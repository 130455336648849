import React, { useState } from "react";
import { useTheme } from "@emotion/react";

import Page from "../../components/Page";

import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

/**
 * Page with UsageInstructionss on how to use the app
 */
const UsageInstructions = () => {
  const [value, setValue] = useState("1");

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <Grid>
        <Stack gap={5} justifyContent="flex-start">
          <Typography variant="h3" fontWeight="bold">
            Präambel/Nutzungshinweise
          </Typography>
          <Stack gap={1} justifyContent="flex-start">
            <Typography variant="h4" fontWeight="bold">
              Die Ziele
            </Typography>
            <Typography variant="body1">
              Diese Feedback-App dient der Erfassung jeglichen Kunden- und
              Mitarbeiterfeedbacks aus unserer täglichen Zusammenarbeit zu allen
              Themen und Leistungen innerhalb der Buderus Partnerwelt. Mit
              dieser einfachen und zentralen Feedback-Erfassung wollen wir eine
              kontinuierliche Verbesserung ermöglichen.
            </Typography>
          </Stack>
          <Stack gap={1} justifyContent="flex-start">
            <Typography variant="h4" fontWeight="bold">
              Die Nutzer
            </Typography>
            <Typography variant="body1">
              In einer Testphase vom xxx bis yyy 2024 erfolgt die exklusive
              Nutzung durch die Abteilung HC/SDE-MK2 inklusive des Buderus
              Partnerwelt-Teams auf Seiten von Q:marketing. Der Versand von
              Feedback findet bereits in der Testphase Buderus weit statt.
              <br />
              Nach dieser Testphase ist ein Buderus weiter Rollout geplant.
            </Typography>
          </Stack>
          <Stack gap={1} justifyContent="flex-start">
            <Typography variant="h4" fontWeight="bold">
              Der Nutzen
            </Typography>

            <ul>
              <li>
                <Typography variant="body1" gutterBottom>
                  Einfaches und schnelles Erfassen jeglicher Art von Kunden-
                  oder Kollegenfeedback zu allen Themen der Partnerwelt mit
                  klarer Zuordnung der zuständigen Ansprechpartner.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Ersatz für eine Vielzahl von bisherigen Einzellösungen für die
                  Erfassung von Feedback: Schmierzettel, Reporting, E-Mails,
                  Ideenplattform, diverse Excellisten inkl. direkter
                  Weiterleitung von Ideen in die Ideenplattform der Q:lub.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Zentrale Speicherung aller Feedbacks in einer Datenbank:
                  Vergleichbarkeit und Auswertbarkeit.
                </Typography>
              </li>
            </ul>
          </Stack>
          <Stack gap={1} justifyContent="flex-start">
            <Typography variant="h4" fontWeight="bold">
              Wichtige Regeln (für die Formulierung der Feedbacks)
            </Typography>
            <ol>
              <li>
                <Typography variant="body1" gutterBottom>
                  Wir nutzen diese Feedback-App{" "}
                  <strong>
                    für alle Feedbacks, die wir rund um die Partnerweltthemen
                    von Kunden oder Kollegen aufnehmen
                  </strong>{" "}
                  und vermeiden parallele E-Mails oder Listen-Führungen zu
                  Feedbacks.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Wir nennen{" "}
                  <strong>
                    keine Namen oder personenbezogenen Daten von Kollegen
                  </strong>{" "}
                  (Buderus Mitarbeitern) bei der Formulierung unserer Feedbacks.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Die{" "}
                  <strong>
                    Nennung von Kunden und Firmen in den Feedbacks ist erlaubt
                    und sinnvoll
                  </strong>
                  .
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Wir formulieren unser Feedback{" "}
                  <strong>kurz und prägnant</strong>.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Wir benennen jedes Feedback mit einem eindeutigen Thema.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Wir formulieren{" "}
                  <strong>
                    höflich und freundlich, ohne persönlich zu werden
                  </strong>
                  .
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Wir ordnen unser Feedback sinnvoll den{" "}
                  <strong>vorgegebenen Kategorien</strong>
                  zu. Damit erhält{" "}
                  <strong>
                    automatisch der aktuell zuständige Ansprechpartner
                  </strong>{" "}
                  das Feedback. Dieser ist über seine Rolle informiert und
                  leitet das Feedback bei Bedarf weiter. Die vorliegende
                  Zuordnung der Ansprechpartner zu den Kategorien ist wichtige
                  Grundlage dafür, dass Verbesserungsmaßnahmen ergriffen werden
                  können, die sich aus den Feedbacks ergeben.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Wir verwenden die Möglichkeit, unser Feedback in{" "}
                  <strong>
                    Cc weiteren Empfänger zukommen zu lassen, sparsam und
                    überlegt
                  </strong>
                  . Ziel dieser App ist die kanalisierte und zentrale
                  Bearbeitung von Feedback. Das breite Streuen von Feedback ist
                  hier oft nicht hilfreich, füllt jedoch unsere Postfächer
                  zusätzlich.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  Eine{" "}
                  <strong>
                    Weiterverfolgung der versendeten Feedbacks durch den
                    Feedback-Verfasser ist nicht vorgesehen
                  </strong>
                  . Die zentrale Speicherung aller erfassten Feedbacks
                  ermöglicht Auswertungen, die bei Bedarf (z. B. wiederholte
                  Feedbacks zu einem Thema) gezielt für ein späteres Nachfassen
                  genutzt werden können.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  <strong>
                    KEIN Feedback sind konkrete Aufgaben, Bitten um Rückruf oder
                    reine Ideen
                  </strong>{" "}
                  (z. B. für neue Werbemittel). Letztere tragen wir weiterhin
                  direkt in die Ideenplattform der Q:lub ein.
                </Typography>
              </li>
            </ol>
            <Typography variant="body1" gutterBottom>
              Unser gemeinsames Ziel der Buderus Partnerwelt „Wir wollen der
              beste Partner für unsere Kunden werden“ setzt stetiges Lernen
              voraus. Für diesen kontinuierlichen Verbesserungsprozess ist jedes
              einzelne Ihrer Feedbacks wertvoll und leistet einen wichtigen
              Beitrag.
              <br /><strong>
              In der Testphase freuen wir uns natürlich auch über alle Feedbacks
              zur Feedback-App.</strong>
              <br />
              Vielen Dank an alle für die rege Nutzung dieses neuen Tools. Jedes
              Feedback bringt uns ein Stück weiter.
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Page>
  );
};

export default UsageInstructions;
