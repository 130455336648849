// AdminRoute.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userIsAdmin } from "../../redux/reducers/userReducer";

/**
 * Wrapper component for routes to only grant access for logged in users
 */
const AdminRoute = (props) => {
  const navigate = useNavigate();
  const isAdmin = useSelector(userIsAdmin);
  
  /**
   * Redirects users that are not logged in
   */
  useEffect(() => {
    if (!isAdmin) {
      navigate("/login");
    }
  }, [isAdmin, navigate]);

  if (isAdmin) {
    return props.children;
  } else {
    return null;
  }
};

export default AdminRoute;
