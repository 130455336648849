import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { toast } from "react-toastify";

import { saveFeedbackFiles } from "../../utils/indexedDBService";

import { addOpenFeedback } from "../../redux/reducers/feedbackReducer";

import Page from "../../components/Page";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useModal } from "mui-modal-provider";
import { ArrowBackIos, InfoOutlined } from "@mui/icons-material";
import CategoryDialog from "../../components/CategoryDialog";
import { getUser } from "../../redux/reducers/userReducer";
import { Offline, Online } from "react-detect-offline";
import { forEach } from "lodash";
import { formatFileSize } from "../../utils/formatFileSize";

/**
 * Page for creating a new feedback
 * -> available in offline mode
 */
const NewFeedback = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mainCategory, setMainCategory] = useState({ id: null, name: null });
  const [subCategory, setSubCategory] = useState({ id: null, name: null });
  const { showModal } = useModal();
  const user = useSelector((state) => getUser(state));

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      message: "",
      files: [],
    },
  });

  const currentValues = watch();

  const usedAttachementSpace = useMemo(() => {
    var size = 0;
    currentValues?.files?.forEach(file => {
      if(typeof file ==  "object"){
        size = size + file.size;
      }
    })
    return size;
  }, [currentValues?.files]);

  useEffect(() => {
    register("title", { required: true });
    register("message", { required: true });
    register("files");
  }, []);

  const onSubmit = async (data) => {
    const files = data.files;

    if (files) {
      try {
        const fileIds = await saveFeedbackFiles(files);

        dispatch(
          addOpenFeedback({
            timestamp: new Date().toISOString(),
            ...data,
            mainCategory: mainCategory,
            subCategory: subCategory,
            files: fileIds,
            creator: {
              id: user?.id,
              firstname: user?.firstname,
              lastname: user?.lastname,
              email: user?.email,
            },
          })
        );
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      dispatch(
        addOpenFeedback({
          timestamp: new Date().toISOString(),
          ...data,
          mainCategory: mainCategory,
          subCategory: subCategory,
          files: [],
          creator: {
            id: user?.id,
            firstname: user?.firstname,
            lastname: user?.lastname,
            email: user?.email,
          },
        })
      );
    }
    navigate("/feedback-erfasst");
  };

  /**
   * Adds selected files to the form value for attachements
   * @param {Event} e - File upload event from file input
   * @returns {void}
   */
  const handleUpload = (e) => {
    var fileStore = currentValues.files;
    const files = e.target.files;
    for(var i = 0; i < files.length; i++){
      //file is smaller than 32MB
      if (files[i].size < 32 * 1024 * 1024) {
         //all files combined are smaller than 100 MB
        if((usedAttachementSpace + files[i].size) < 100 * 1024 * 1024){
          fileStore = [...fileStore, files[i]]
        }else{
          toast.error(`Die Anhänge dürfen insgesamt nicht 100 MB überschreiten.`)
        }
      }else{
        toast.error(`Datei "${files[i]?.name}" überschreitet 32 MB.`)
      }
    }
    setValue("files", fileStore);
  };

  /**
   * Removes selected file from the form value for files
   * @param {number} index - index of the file in the files array
   * @returns {void}
   */
  const handleFileDelete = (index) => {
    const updatedFiles = currentValues.files;
    if (index !== -1) {
      updatedFiles.splice(index, 1);
      setValue("files", updatedFiles);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  /**
   * Opens Dialog to select a category
   * @param {Event} e - The event object representing the click event to select categorys
   * @returns {void}
   */
  const handleSelectCategory = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const categoryDialog = showModal(CategoryDialog, {
      selectedMainCategory: mainCategory,
      selectedSubCategory: subCategory,
      onCancel: () => {
        categoryDialog.destroy();
      },
      onConfirm: (mainCategory, subCategory) => {
        setMainCategory(mainCategory);
        setSubCategory(subCategory);
        categoryDialog.destroy();
      },
    });
  };

  return (
    <Page>
      <Grid>
        <Stack gap={3} justifyContent="flex-start">
          <Stack
            direction={{xs: "column", sm: "row"}}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" fontWeight="bold">
              Neues Feedback erfassen
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <InfoOutlined />
              <Link
                component="button"
                variant="h5"
                underline="hover"
                sx={{ color: "white" }}
                onClick={() => navigate("nutzung")}
              >
                Präambel/Nutzungshinweise
              </Link>
            </Stack>
          </Stack>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3}>
              <Box>
                <Typography variant="h5" marginBottom={1} display="flex">
                  Thema
                </Typography>
                <TextField
                  hiddenLabel
                  fullWidth
                  inputProps={{ maxLength: 60 }}
                  placeholder="Worum geht es?"
                  value={currentValues.title}
                  helperText={`${currentValues.title.length}/60 Zeichen`}
                  onChange={(e) => {
                    setValue("title", e.target.value);
                  }}
                  error={!!errors?.title}
                />
              </Box>
              <Box>
                <Typography variant="h5" marginBottom={1} display="flex">
                  Feedback{" "}
                </Typography>
                <TextField
                  hiddenLabel
                  fullWidth
                  multiline
                  placeholder="Was möchten Sie mitteilen?"
                  variant="outlined"
                  minRows={5}
                  maxRows={8}
                  value={currentValues.message}
                  onChange={(e) => {
                    setValue("message", e.target.value);
                  }}
                  error={!!errors?.message}
                />
              </Box>
              <Box>
                <Online>
                  <Stack
                    onClick={handleSelectCategory}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography variant="h5">Kategorie</Typography>
                    {mainCategory?.name && subCategory?.name && (
                      <Typography variant="body1">
                        {mainCategory.name}
                        {" > "}
                        {subCategory.name}
                      </Typography>
                    )}
                    {!(mainCategory.name && subCategory.name) && (
                      <Typography variant="body1">
                        Keine Kategorie ausgewählt
                      </Typography>
                    )}
                    <ArrowBackIos />
                  </Stack>
                </Online>
                <Offline>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={2}
                  >
                    <Typography variant="h5">Kategorie</Typography>
                    <Typography variant="body1">
                      Offline nicht verfügbar
                    </Typography>
                  </Stack>
                </Offline>
              </Box>
              <Box>
                <Stack gap={1}>
                  {" "}
                  {currentValues.files &&
                    Array.isArray(currentValues.files) &&
                    currentValues.files?.length > 0 &&
                    currentValues.files?.map((file, index) => {
                      return (
                        <Box key={`file-${index}`}>
                          <Stack
                            direction="row"
                            gap={2}
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ marginBottom: "5px" }}
                          >
                            <Typography>{`${file.name} (${formatFileSize(file?.size)})`}</Typography>
                            <DeleteIcon
                              onClick={() => handleFileDelete(index)}
                            />
                          </Stack>
                        </Box>
                      );
                    })}
                </Stack>
                <Button
                  component="label"
                  variant="contained"
                  onChange={handleUpload}
                  sx={{
                    border: "unset",
                    backgroundColor: "unset",
                    color: theme.palette.secondary.main,
                    fontSize: "1rem",
                    padding: 0,
                    margin: 0,
                    cursor: "pointer",
                    "&:hover": {
                      fontWeight: "900",
                      backgroundColor: "unset",
                      boxShadow: "unset",
                    },
                  }}
                >
                  + Anhang hinzufügen
                  <VisuallyHiddenInput accept=".pdf, .png, .jpeg, .doc, .gif, .csv, .xls, .ppt" type="file" multiple />
                </Button>
                &nbsp; <small>(max. Dateigröße von 32 MB)</small>
              </Box>

              <Button
                variant="secondary"
                sx={{ backgroundColor: theme.palette.secondary.main }}
                size="large"
                type="submit"
                disabled={
                  !currentValues.title ||
                  !currentValues.message ||
                  (navigator.onLine
                    ? !mainCategory?.id || !subCategory?.id
                    : false)
                }
              >
                Speichern
              </Button>
            </Stack>
          </form>
        </Stack>
      </Grid>
    </Page>
  );
};

export default NewFeedback;
