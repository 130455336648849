export function formatFileSize(bytes) {
    if (bytes === 0) return '0 B';
  
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const decimalPlaces = 1;
  
    const exponent = Math.min(Math.floor(Math.log10(bytes) / 3), units.length - 1);
    const size = (bytes / Math.pow(1024, exponent)).toFixed(decimalPlaces);
  
    return `${size} ${units[exponent]}`;
  }