import BottomBar from "../BottomBar";
import { Box } from "@mui/material";

/**
 * Wrapper component representing a page
 */
export default function Page({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        padding: "1rem 1rem 80px 1rem",
        margin: "auto",
        maxWidth: {
          lg: "1000px",
        },
        paddingTop:{
          lg: "5vh"
        }
      }}
    >
      
      {children}
      <BottomBar />
     
    </Box>
  );
}
