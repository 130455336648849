import React from "react";
import { useNavigate } from "react-router-dom";

import { Online, Offline } from "react-detect-offline";

import Page from "../../components/Page";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

import { Alert, Button, Grid, Stack, Typography } from "@mui/material";

const Success = () => {
  const navigate = useNavigate();

  /**
   * Page after successfully creating an feedback
   * -> Offline: only option is to redirect to overview
   * -> Online: options to send feedback
   */
  return (
    <Page>
      <Grid>
        <Stack gap={8}>
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="center"
            marginTop={10}
          >
            <CheckIcon />
            <Typography variant="h3" fontWeight="bold" textAlign="center">
              Feedback erfasst.
            </Typography>
          </Stack>
          <Online>
            <Stack gap={2}>
              <Typography variant="h4" align="center">
                Wollen Sie das Feedback an einen Ansprechpartner versenden?
              </Typography>
              <Alert severity="warning" variant="outlined">
             <strong>Mails an Empfänger außerhalb von MK2 und Q: werden in der Testphase nicht versendet, sondern gehen an TG, CF und MxB. Es geht kein Feedback verloren.</strong></Alert>
              <Button
                variant="contained"
                color="invertedGrayScale"
                size="large"
                fullWidth
                onClick={() => navigate("/feedback-versenden")}
              >
                Feedback versenden
              </Button>
              <Typography
                variant="body1"
                align="center"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/sammlung");
                }}
              >
                Später entscheiden
              </Typography>
            </Stack>
          </Online>
          <Offline>
            <Button
              variant="contained"
              color="invertedGrayScale"
              size="large"
              fullWidth
              onClick={() => {
                navigate("/sammlung");
              }}
            >
              Zu den Entwürfen
            </Button>
          </Offline>
        </Stack>
      </Grid>
    </Page>
  );
};

export default Success;
