import { toast } from "react-toastify";

/**
 * Sets up indexedDB database named Feedback for unsent Feedback files or
 * opens an existing database object
 * @returns database object
 */

export function openDatabase() {
  const dbName = "Feedback";
  const request = indexedDB.open(dbName, 1);

  let db;

  request.onupgradeneeded = (event) => {
    db = event.target.result;
    const objectStore = db.createObjectStore("feedbackFiles", {
      keyPath: "id",
      autoIncrement: true,
    });
    objectStore.createIndex("name", "name", { unique: false });
  };

  return new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

/**
 * Adds data to the feedbackFiles object store
 * @param {*} db - database object
 * @param {*} data - file data
 * @returns Promise
 */
export function addDataToStore(db, data) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["feedbackFiles"], "readwrite");
    const objectStore = transaction.objectStore("feedbackFiles");
    const request = objectStore.add(data);

    request.onsuccess = (event) => {
      const key = event.target.result;
      resolve(key);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

/**
 * Returns file by key
 * @param {*} db - database object
 * @param {*} key - database entry key
 * @returns Promise
 */
export function getFileByKey(db, key) {
  const transaction = db.transaction(["feedbackFiles"], "readonly");
  const objectStore = transaction.objectStore("feedbackFiles");
  const request = objectStore.get(key);

  return new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      const fileData = event.target.result;
      resolve(fileData);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

/**
 * Deletes File entry from database by key
 * @param {*} db - database object
 * @param {*} key - Database entry key
 * @returns Promise
 */
const deleteFileByKey = (db, key) => {
  const transaction = db.transaction(["feedbackFiles"], "readwrite");
  const objectStore = transaction.objectStore("feedbackFiles");

  const request = objectStore.delete(key);

  return new Promise((resolve, reject) => {
    transaction.oncomplete = () => {
      resolve(`File with key ${key} deleted successfully`);
    };

    transaction.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

/**
 * Removes file from indexedDB
 * @param {number} id - image id in indexedDB
 * @returns {void}
 */
export async function deleteFile(id) {
  const db = await openDatabase();

  await deleteFileByKey(db, id).catch((error) => {
    console.error("Error:", error);
  });
}

/**
 * Deletes multiple Files from database by ids
 * @param {array} fileIds
 */
export async function deleteFilesByIds(fileIds) {
  try {
    if (fileIds && Array.isArray(fileIds)) {
      const db = await openDatabase();
      fileIds.forEach((id) => {
        deleteFile(id);
      });
      db.close();
    }
  } catch (err) {
    console.error(err);
  }
}

/**
 * Gets all File entries from database
 * @param {*} db - database object
 * @returns Promise
 */
export function getAllFiles(db) {
  const transaction = db.transaction(["feedbackFiles"], "readonly");
  const objectStore = transaction.objectStore("feedbackFiles");
  const files = [];

  return new Promise((resolve, reject) => {
    objectStore.openCursor().onsuccess = (event) => {
      const cursor = event.target.result;

      if (cursor) {
        files.push(cursor.value);
        cursor.continue();
      } else {
        resolve(files);
      }
    };

    transaction.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

/**
 * Retrieves all files for open feedback files from the indexedDB
 * @returns array of files
 */
export async function getFiles() {
  var result = [];
  const db = await openDatabase();
  await getAllFiles(db).then((data) => {
    result = data;
    db.close();
  });
  return result;
}

/**
 * Resets database
 * @param {*} db - database object
 * @returns
 */
const resetDatabase = (db) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["feedbackFiles"], "readwrite");
    const objectStore = transaction.objectStore("feedbackFiles");

    const request = objectStore.clear();

    request.onsuccess = (event) => {
      resolve("Database cleared successfully");
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

/**
 * Resets the IndexedDB for feedback files
 * @returns {void}
 */
export function resetIndexedDB() {
  openDatabase().then((db) => {
    resetDatabase(db)
      .then(() => {
        toast.success("Daten wurden zurückgesetzt.");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    db.close();
  });
}

/**
 * Saves files to database
 * @param {array} files
 * @returns keys
 */
export async function saveFeedbackFiles(files) {
  const db = await openDatabase();

  const filePromises = files.map(async (file) => {
    const key = await addDataToStore(db, { type: "file", data: file });
    return key;
  });

  var fileIds = [];
  await Promise.all(filePromises).then((ids) => {
    fileIds = ids;
    db.close();
  });
  return fileIds;
}

/**
 * Retrieves files from indexedDB for a list of keys
 * @param {array} fileIds - list with keys
 * @returns list of files
 */
export async function getFilesByIds(fileIds) {
  try {
    var result = [];
    if (fileIds && Array.isArray(fileIds)) {
      const db = await openDatabase();
      await getAllFiles(db).then((data) => {
        if (data) {
          result = data.filter((file) => fileIds.includes(file.id));
        }
      });
      db.close();
    }
    return result;
  } catch (err) {
    console.error(err);
  }
}
