import axios from "axios";
import { toast } from "react-toastify";

const config = {
  withCredentials: true,
  crossDomain: true,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  maxRedirects: 0,
}

/**
 * POST
 * request to authenticate user
 * @param {{username, password}} credentials 
 * @returns void
 */
export const authenticate = async (credentials) => {
  const api = process.env.REACT_APP_API;
  if (api && credentials) {
    try {
      const response = await axios.post(`${api}/auth`, credentials, config);
      return response.data;
    } catch (error) {
      toast.error("Fehler beim Login: bitte probieren Sie es nochmal.");
      throw error;
    }
  }
};

/**
 * POST
 * Logs out - removes session key
 * @returns void
 */
export const logout = async () => {
  const api = process.env.REACT_APP_API;
  if (api) {
    try {
      const response = await axios.post(`${api}/logout`, config);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * GET
 * Logs out - removes session key
 * @returns boolean
 */
export const checkLoginStatus = async () => {
  const api = process.env.REACT_APP_API;
  if (api) {
    try {
      const response = await axios.get(`${api}/isLoggedIn`, config);
      return response.status === 200;
    } catch (error) {
      return false;
    }
  }
};

/**
 * GET
 * Fetches categories without contacts
 * @returns {} categories
 */
export const fetchCategories = async () => {
  const api = process.env.REACT_APP_API;
  if (api) {
    try {
      const response = await axios.get(`${api}/categories`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        console.error('Error:', error.message);
        toast.error("Daten konnten nicht geladen werden.");
      }
    }
  }
};

/**
 * GET
 * Fetches category contacts
 * @returns {} categories
 */
export const fetchCategoryContacts = async (id) => {
  const api = process.env.REACT_APP_API;
  if (api && id) {
    try {
      const response = await axios.get(`${api}/subcategory/${id}/contacts`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        console.error('Error:', error.message);
        toast.error("Daten konnten nicht geladen werden.");
      }
    }
  }
};


/**
 * GET
 * Fetches all feedbacks for admin view
 * @returns [] feedbacks
 */
export const fetchAllFeedbacks = async () => {
  const api = process.env.REACT_APP_API;
  if (api) {
    try {
      const response = await axios.get(`${api}/feedback`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        console.error('Error:', error.message);
        toast.error("Daten konnten nicht geladen werden.");
      }
    }
  }
};

/**
 * GET
 * Fetche feedback based on feedback id
 * @param {int} id 
 * @returns {} feedback
 */
export const fetchOneFeedback = async (id) => {
  const api = process.env.REACT_APP_API;
  if (api && id) {
    try {
      const response = await axios.get(`${api}/feedback/${id}`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        console.error('Error:', error.message);
        toast.error("Daten konnten nicht geladen werden.");
      }
    }
  }
};

/**
 * GET
 * Fetches feedbacks based on contact id
 * @param {int} id 
 * @returns [] feedbacks
 */
export const fetchOwnSendFeedbacks = async (id) => {
  const api = process.env.REACT_APP_API;
  if (api && id) {
    try {
      const response = await axios.get(`${api}/feedback/own/${id}`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        console.error('Error:', error.message);
        if(navigator.onLine){
          toast.error("Daten konnten nicht geladen werden.");
        }
       
      }
    }
  }
};

/**
 * GET
 * Fetches SBC2 contacts
 * @returns [] contacts
 */
export const fetchSBC2Contacts = async () => {
  const api = process.env.REACT_APP_API;
  if (api) {
    try {
      const response = await axios.get(`${api}/contacts/group/sbc2`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        console.error('Error:', error.message);
        toast.error("Daten konnten nicht geladen werden.");
      }
    }
  }
};

/**
 * POST
 * Created Feedback
 * @param {object} postData 
 * @returns {id} 
 */
export const createFeedback = async (postData) => {
  const api = process.env.REACT_APP_API;
  if (api && postData) {
    try {
      const response = await axios.post(`${api}/feedback`, postData, config)
      return response.data;
    } catch (error) {
      throw error; 
    }
  }
};

/**
 * POST
 * Sends a mail to recipients based on feedback id
 * @param {int} id - feedback id
 * @returns void
 */
export const sendMail = async (id) => {
  const api = process.env.REACT_APP_API;
  if (api && id) {
    try {
      const response = await axios.post(`${api}/email/feedback/${id}`, {} , config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

/**
 * GET
 * Fetches file based on file id and srcFileName
 * @param {int} fileId 
 * @param {string} srcFileName 
 */
export const downloadFile = async (fileId, srcFileName) => {
  const api = process.env.REACT_APP_API;
  if (api && fileId) {
    try {
      const response = await axios.get(`${api}/feedback/file/${fileId}`, {
        ...config,
        responseType: "blob",
      });
 
     // Extracting content type from response headers
     const contentType = response.headers['content-type'];

     // Extracting filename from response headers, assuming it comes as 'Content-Disposition'
     const filename = srcFileName || `file_${fileId}`;
     
     // Create a link element
     const link = document.createElement('a');
     link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
     link.setAttribute('download', filename);
     
     // Append the link to the body and trigger the download
     document.body.appendChild(link);
     link.click();
     
     // Clean up
     document.body.removeChild(link);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        toast.error("Datei konnte nicht heruntergeladen werden.");
        console.error('Error:', error.message);
      }
    }
  }
};

/**
 * GET
 * Fetches Sales structure
 * @returns [] 
 */
export const fetchSalesStructure = async () => {
  const api = process.env.REACT_APP_API;
  if (api) {
    try {
      const response = await axios.get(`${api}/structure/sales`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        toast.error("Daten konnten nicht geladen werden.");
        console.error('Error:', error.message);
      }
    }
  }
};

/**
 * GET
 * Fetches CC List contacts
 * @returns [] contacts
 */
export const fetchCcListContacts = async () => {
  const api = process.env.REACT_APP_API;
  if (api) {
    try {
      const response = await axios.get(`${api}/contacts/ccList`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw error;  
      } else {
        console.error('Error:', error.message);
        toast.error("Daten konnten nicht geladen werden.");
      }
    }
  }
};
