import React from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "../components/PrivateRoute";
import AdminRoute from "../components/AdminRoute";

import Login from "./Login";
import NewFeedback from "./Feedback/NewFeedback";
import Overview from "./Overview";
import Success from "./Feedback/Success";
import SBC2 from "./Feedback/Assignment/SBC2";
import Sales from "./Feedback/Assignment/Sales";
import Departments from "./Feedback/Assignment/Departments";
import UsageInstructions from "../routes/More/UsageInstructions";
import Admin from "./Admin";
import SendFeedback from "./Feedback/SendFeedback";

/**
 * Defines routes for the app
 */
const AppRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <NewFeedback />
          </PrivateRoute>
        }
      />

      <Route
        path="/feedback-erfasst"
        element={
          <PrivateRoute>
            <Success />
          </PrivateRoute>
        }
      />
      <Route
        path="/feedback-versenden"
        element={
          <PrivateRoute>
            <SendFeedback/>
          </PrivateRoute>
        }
      />

      <Route
        path="/sammlung"
        element={
          <PrivateRoute>
            <Overview />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <AdminRoute>
            <Admin />
          </AdminRoute>
        }
      />
      <Route
        path="/nutzung"
        element={
          <PrivateRoute>
            <UsageInstructions />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRouter;
