import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "mui-modal-provider";

import {
  deleteFeedback,
  getActiveFeedback,
} from "../../redux/reducers/feedbackReducer";

import { toast } from "react-toastify";
import { Online } from "react-detect-offline";

import Page from "../../components/Page";
import ContactDialog from "../../components/ContactDialog";

import {
  Stack,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  Chip,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { getUser } from "../../redux/reducers/userReducer";
import { Container } from "@mui/system";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createFeedback,
  fetchCategoryContacts,
  sendMail,
} from "../../utils/queries";
import { deleteFilesByIds, getFilesByIds } from "../../utils/indexedDBService";
import { createFeedbackFormData } from "../../utils/feedbackFormData";
import { AddCircle, ExpandMore } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import LoadingDialog from "../../components/LoadingDialog";

/**
 * Page for sending/assigning a feedback
 */
const SendFeedback = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const queryClient = useQueryClient();
  const user = useSelector((state) => getUser(state));
  const [loading, setLoading] = useState(false);

  const sendFeedbackMutation = useMutation({
    mutationFn: createFeedback,
    onSuccess: (data) => {
      if (data?.id) {
        sendMailMutation.mutate(data.id);
        console.log("Mailversand");
      }
      if (activeFeedback.files) {
        deleteFilesByIds(activeFeedback.files);
      }
      dispatchDeleteFeedback(activeFeedback.id);
      navigate("/sammlung");
      toast.info("Feedback wurde angelegt.");
      queryClient.invalidateQueries(["own_feedbacks", "all_feedbacks"]);
    },
    onError: (err) => {
      console.error(err);
      toast.error("Feedback konnte nicht versendet werden.");
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const sendMailMutation = useMutation({
    mutationFn: sendMail,
    onError: (err) => {
      toast.error("Fehler beim Mailversand.");
    },
  });

  const [copyContacts, setCopyContacts] = useState([]);
  const [comment, setComment] = useState("");
  const [sendToIPF, setSendToIPF] = useState(false);
  const [ccInput, setCcInput] = useState({ value: "", error: false });
  const [ccEmails, setCcEmails] = useState([]);

  const activeFeedback = useSelector((state) => getActiveFeedback(state));
  const {
    data: categoryContacts,
    isLoading,
    error,
  } = useQuery(
    ["categoryContacts", activeFeedback?.subCategory?.id],
    () => fetchCategoryContacts(activeFeedback?.subCategory?.id),
    {
      //1800000 = 30 minutes
      c: 1,
    }
  );

  const [selectedCategoryContacts, setSelectedCategoryContacts] = useState([]);

  useEffect(() => {
    if (!isLoading && error) {
      navigate("/sammlung");
      toast.error(
        "Der Ansprechpartner der Kategorie konnte nicht geladen werden."
      );
    }
  }, [isLoading, error]);

  /**
   * Toggles checked state of the contact
   * @param {Object} contact - object representing contact
   * @returns {void}
   */
  const handleToggle = (contactId) => {
    const isChecked = selectedCategoryContacts?.some((id) => id === contactId);
    const newChecked = isChecked
      ? selectedCategoryContacts?.filter((id) => id !== contactId)
      : [...selectedCategoryContacts, contactId];
    setSelectedCategoryContacts(newChecked);
  };

  //IndexedDB files
  const [files, setFiles] = useState();

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const result = await getFilesByIds(activeFeedback.files);
      setFiles(result);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Dispatches to the redux store to delete Feedback from the open list
   * @returns {void}
   */
  const dispatchDeleteFeedback = () => {
    dispatch(deleteFeedback(activeFeedback.id));
  };

  /**
   * Sends Feedback and redirects
   * @param {Event} e - The event object representing the form submit
   * @returns {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    createFeedbackFormData(
      activeFeedback,
      comment,
      user.id,
      sendToIPF,
      categoryContacts?.length > 1
        ? selectedCategoryContacts
        : [categoryContacts[0].id],
      copyContacts?.length > 0
        ? copyContacts.map((contact) => contact.id)
        : null,
      ccEmails,
      files
    ).then((formData) => {
      setLoading(true);
      sendFeedbackMutation.mutate(formData);
    });
  };

  /**
   * Opens Dialog to select contacts
   * @param {Event} e - The event object representing the click event to select contacts
   * @returns {void}
   */
  const handleSelectContacts = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const contactsDialog = showModal(ContactDialog, {
      checkedContacts: copyContacts,
      ipfChecked: sendToIPF,
      optional: true,
      onCancel: () => {
        contactsDialog.destroy();
      },
      onConfirm: (checked, ipfChecked) => {
        setCopyContacts(checked);
        setSendToIPF(ipfChecked);
        contactsDialog.destroy();
      },
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const addCCEmail = () => {
    const email = ccInput;
    if (validateEmail(email)) {
      if (!ccEmails.includes(email)) {
        setCcEmails([...ccEmails, email]);
      }
      setCcInput({ value: "", error: false });
    } else {
      setCcInput({ ...ccInput, error: true });
    }
  };

  const handleRemoveCcEmail = (index) => {
    setCcEmails([...ccEmails.filter((el, i) => i !== index)]);
  };

  const handleRemoveContact = (contactId) => {
    setCopyContacts([...copyContacts.filter((el) => el.id !== contactId)]);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Page>
      <Grid>
        <Stack
          direction="row"
          alignItems="center"
          marginTop={1}
          marginBottom={2}
          onClick={handleBack}
        >
          <ArrowBackIos />
          <Typography variant="subtitle1">Zurück</Typography>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h4">Feedback versenden</Typography>
          <Alert severity="warning" variant="outlined">
            <strong>
              Mails an Empfänger außerhalb von MK2 und Q: werden in der
              Testphase nicht versendet, sondern gehen an TG, CF und MxB. Es
              geht kein Feedback verloren.
            </strong>
          </Alert>
          <Container disableGutters>
            <Typography variant="h5">Zusammenfassung</Typography>
            <Divider sx={{ margin: "5px 0 0 0" }} />
            <Stack direction="column" gap={"5px"} alignItems="flex-start">
              <Typography fontWeight="bold" variant="body1">
                {activeFeedback?.title}
              </Typography>
              <Typography variant="body1">
                {activeFeedback?.message?.slice(0, 40)}
                {activeFeedback?.message?.length > 40 ? " (...)" : ""}
              </Typography>
              <Typography variant="body1">
                <Stack direction={"row"} gap={1}>
                  <Chip
                    variant="outlined"
                    label={`${activeFeedback?.mainCategory?.name} > ${activeFeedback?.subCategory?.name}`}
                  />
                </Stack>
              </Typography>
            </Stack>
            <Divider sx={{ margin: "5px 0 0 0" }} />
          </Container>
          <Stack direction="column">
            <Stack
              direction="row"
              justifyContent={"flex-start"}
              alignItems={"center"}
              useFlexGap
              flexWrap="wrap"
              gap={"5px"}
              sx={{
                minHeight: "28px",
                marginTop: "5px",
                width: "98%",
                backgroundColor: theme.palette.grayScale.contrastText,
                borderRadius: "5px",
                padding: "5px 8px",
              }}
            >
              <Typography variant="body1" marginRight={1} display="flex">
                Empfänger:
              </Typography>
              {categoryContacts &&
                Array.isArray(categoryContacts) &&
                categoryContacts.length > 1 &&
                categoryContacts.map((contact) => {
                  if (
                    !!selectedCategoryContacts.find((id) => id == contact.id)
                  ) {
                    return (
                      <Chip
                        sx={{ margin: 0 }}
                        variant="outlined"
                        label={`${contact.firstname} ${contact.lastname} (${contact.department}) <${contact.email}>`}
                        style={{ width: "fit-content" }}
                        onDelete={() => handleToggle(contact.id)}
                      />
                    );
                  }
                  return null;
                })}
              {categoryContacts &&
                Array.isArray(categoryContacts) &&
                categoryContacts.length == 1 && (
                  <Chip
                    sx={{ margin: 0 }}
                    variant="outlined"
                    label={`${categoryContacts[0]?.firstname} ${categoryContacts[0]?.lastname} (${categoryContacts[0]?.department}) <${categoryContacts[0].email}>`}
                    style={{ width: "fit-content" }}
                  />
                )}
            </Stack>
            <Stack
              direction="row"
              gap={"3px"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              useFlexGap
              flexWrap="wrap"
              sx={{
                minHeight: "28px",
                marginTop: "2px",
                width: "98%",
                backgroundColor: theme.palette.grayScale.contrastText,
                borderRadius: "5px",
                padding: "5px 8px",
              }}
            >
              <Typography variant="body1" marginRight={1} display="flex">
                In Kopie an:
              </Typography>
              {copyContacts &&
                Array.isArray(copyContacts) &&
                copyContacts?.length > 0 &&
                copyContacts?.map((contact, index) => (
                  <Chip
                    sx={{ margin: 0 }}
                    variant="outlined"
                    label={`${contact.firstname} ${contact.lastname} (SBC2/Q:) <${contact.email}>`}
                    style={{ width: "fit-content" }}
                    onDelete={() => handleRemoveContact(contact.id)}
                  />
                ))}
              {ccEmails &&
                Array.isArray(ccEmails) &&
                ccEmails?.length > 0 &&
                ccEmails?.map((email, index) => (
                  <Chip
                    sx={{ margin: 0 }}
                    variant="outlined"
                    label={`${email}`}
                    style={{ width: "fit-content" }}
                    onDelete={() => handleRemoveCcEmail(index)}
                  />
                ))}
              {sendToIPF && (
                <Chip
                  variant="outlined"
                  label={`Ideenplattform`}
                  style={{ width: "fit-content" }}
                  onDelete={() => setSendToIPF(false)}
                />
              )}
            </Stack>
          </Stack>
          <Divider sx={{ margin: "5px 0 0 0" }} />
          <Stack gap={0}>
            {categoryContacts && categoryContacts.length > 1 && (
              <>
                <Typography variant="h5" marginBottom={1} display="flex">
                  Empfänger auswählen
                </Typography>
                <Typography variant="body1" marginBottom={0} display="flex">
                  Bitte wählen mindestens einen Kontakt aus.
                </Typography>
                <FormControl
                  sx={{ ml: 2 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <Stack
                      direction="row"
                      gap={"5px"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      marginBottom={"10px"}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      {categoryContacts.map((contact) => (
                        <FormControlLabel
                          sx={{ width: "45%" }}
                          control={
                            <Checkbox
                              checked={
                                !!selectedCategoryContacts?.find(
                                  (id) => id == contact.id
                                )
                              }
                              onChange={() => handleToggle(contact.id)}
                            />
                          }
                          label={`${contact.firstname} ${contact.lastname} (${contact.department})`}
                        />
                      ))}
                    </Stack>
                  </FormGroup>
                </FormControl>
              </>
            )}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              marginTop="15px"
              gap={2}
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                gap={1}
                width={{ xs: "100%", sm: "50%" }}
                alignItems="center"
              >
                <TextField
                  size="small"
                  placeholder="Zusätzliche Email-Adressen (optional)"
                  value={ccInput?.value}
                  onChange={(e) => {
                    setCcInput(e.target.value);
                  }}
                  fullWidth
                  variant="outlined"
                  error={ccInput?.error}
                  helperText={
                    ccInput?.error ? "Bitte gültige Email-Adresse eingeben" : ""
                  }
                />
                <AddCircle onClick={addCCEmail} />
              </Stack>
              <Button
                size={"small"}
                variant="outlined"
                onClick={handleSelectContacts}
              >
                Zusätzliche MK2/Q: Kontakte
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ margin: "5px 0 0 0" }} />
          <Stack gap={0}>
            <Typography variant="h5" marginBottom={1} display="flex">
              Persönliche Nachricht
            </Typography>
            <TextField
              hiddenLabel
              fullWidth
              multiline
              placeholder="Ihre persönliche Nachricht (optional)"
              variant="outlined"
              minRows={2}
              maxRows={4}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Stack>
          <Online>
            <Button
              variant="secondary"
              size="large"
              fullWidth
              onClick={handleSubmit}
              disabled={
                categoryContacts?.length > 1
                  ? !selectedCategoryContacts.length > 0
                  : false
              }
            >
              Jetzt versenden
            </Button>
          </Online>
        </Stack>
      </Grid>
      <LoadingDialog open={loading} title={"Feedback wird versendet..."} />
    </Page>
  );
};

export default SendFeedback;
