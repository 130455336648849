export async function createFeedbackFormData(
  activeFeedback,
  comment,
  creatorId,
  isBscThread,
  contacts,
  copyContacts,
  ccEmails,
  files
) {
    const formData = new FormData();
    formData.append("title", activeFeedback.title);
    formData.append("message", activeFeedback.message);
    formData.append("mainCategory", activeFeedback.mainCategory.id);
    formData.append("subCategory", activeFeedback.subCategory.id);
    formData.append("timestamp", activeFeedback.timestamp);
    formData.append("creatorId", creatorId);

    if(isBscThread){
      formData.append("isBscThread", isBscThread);
    }

    if(contacts){
      contacts.forEach(contactId => {
        formData.append("recipients[]", contactId);
      })
    }

    if(copyContacts){
      copyContacts.forEach(contactId => {
        formData.append("copyRecipients[]", contactId);
      })
    }

    if (comment !== "") {
      formData.append("comment", comment);
    }
    
    if(ccEmails){
      ccEmails.forEach(email => {
        formData.append("ccEmails[]", email);
      })
    }

    if (files) {
      files.forEach((file) => {
        formData.append("files[]", file.data);
      });
    }

    return formData;
}
