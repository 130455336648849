import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Slide,
  Stack,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import { fetchCategories } from "../../utils/queries";
import { logoutUser } from "../../redux/reducers/userReducer";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

/**
 * Dialog to select SBC2 contact data
 * @param {boolean} open - represents wether the dialog is open or not
 * @param {function} onClose - callback Function triggerend on closing event of the dialog
 * @param {function} onConfirm - callback Function triggerend on clicking the confirm button
 * @param {Array} checkedContacts - Array of checked contacts
 * @param {function} setCheckedContacts - callback Function to update checkedContacts in parent component
 * @returns
 */
export default function CategoryDialog({
  open,
  onConfirm,
  onCancel,
  selectedMainCategory,
  selectedSubCategory,
  ...props
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mainCategory, setMainCategory] = useState(selectedMainCategory);
  const [subCategory, setSubCategory] = useState(selectedSubCategory);
  const [openStates, setOpenStates] = useState({});
  const { data: categoryList, isLoading, error} = useQuery(['categories'], fetchCategories, {
  });

  useEffect(()=> {
    if(error?.response?.status === 403){
      dispatch(logoutUser());
      navigate("/login");
      toast.error("Fehlende Berechtigungen: bitte loggen Sie sich erneut ein.");
    }
  },[error]);

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      TransitionComponent={Transition}
      anchor="left"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.grayScale.main,
          color: theme.palette.primary.contrastText,
          width: isMobile ? "100%" : "50%",
        },
      }}
    >
      <Stack p={2} gap={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Typography variant="h3" fontWeight="bold">
            Kategorie auswählen
          </Typography>
          <IconButton size="large" onClick={onCancel}>
            <Close size="large" />
          </IconButton>
        </Stack>
        <Typography variant="body1">
          Bitte wählen sie eine übergeordnete und eine untergeordnete Kategorie für
          Ihr Feedback aus.
        </Typography>
        {isLoading ? 
          <CircularProgress sx={{margin: "20px auto",}}/>
        :
        <Box>
          {Array.isArray(categoryList) &&
            categoryList.map((main) => {
              const isOpen = openStates[main.name] || false;

              const handleClick = () => {
                setOpenStates((prevState) => ({
                  ...prevState,
                  [main.name]: !isOpen,
                }));
              };
              return (
                <List
                  key={`mainCategory-${main.name}`}
                  sx={
                    isMobile
                      ? { maxHeight: "75dvh", overflowY: "auto", marginTop: 1 }
                      : { maxHeight: "90dvh", overflowY: "auto", marginTop: 1 }
                  }
                >
                  <ListItem
                    onClick={handleClick}
                    sx={
                      mainCategory.id == main.id
                        ? { backgroundColor: "#EE7203" }
                        : {}
                    }
                  >
                    <ListItemText
                      primary={
                        <Stack direction="row">
                          {main?.subcategories &&
                            (isOpen ? (
                              <ExpandLessIcon sx={{ marginRight: "5px" }} />
                            ) : (
                              <ExpandMoreIcon sx={{ marginRight: "5px" }} />
                            ))}
                          <Typography variant="h6">{main.name}</Typography>
                        </Stack>
                      }
                    />
                    <Typography variant="subtitle1">
                      {main?.subcategories?.length}
                    </Typography>
                  </ListItem>
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List>
                      {Array.isArray(main.subcategories) &&
                        main?.subcategories?.map((sub) => {
                          return (
                            <ListItem
                              sx={
                                subCategory.id == sub.id
                                  ? {
                                      paddingLeft: 6,
                                      cursor: "pointer",
                                      border: "1px solid #EE7203",
                                    }
                                  : { paddingLeft: 6, cursor: "pointer" }
                              }
                              key={`subCategory-${sub.id}`}
                            >
                              <ListItemButton
                                onClick={() => {
                                  setMainCategory({id: main.id, name: main.name});
                                  setSubCategory({id: sub.id, name: sub.name})
                                }
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography variant="h6">
                                      {sub.name}
                                    </Typography>
                                  }
                                />
                                {subCategory.id == sub.id && (
                                  <Typography variant="subtitle1">✓</Typography>
                                )}
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                    </List>
                  </Collapse>
                </List>
              );
            })}
        </Box>
}
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            onConfirm(mainCategory, subCategory);
          }}
          disabled={!mainCategory && !subCategory}
        >
          Auswahl übernehmen
        </Button>
      </Stack>
    </Drawer>
  );
}
