const LOGIN_USER = "app/user/LOGIN_USER";
const LOGOUT_USER = "app/user/LOGOUT_USER";

const initialState = {
  id: "",
  username: "",
  isLoggedIn: false,
  admin: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      return {
        ...state,
        isLoggedIn: true,
        ...action.user
      };
    }
    case LOGOUT_USER: {
        return {
          ...state,
          isLoggedIn: false,
          id: ""
        };
      }
    default:
      return state;
  }
};

//actions
export const loginUser = (user) => ({
  type: LOGIN_USER,
  user
});

export const logoutUser = () => ({
    type: LOGOUT_USER
  });

//selector
export const getKontaktID = (state) => state.user.id;
export const getUser = (state) => state.user
export const userIsLoggedIn = (state) => state.user.isLoggedIn;
export const userIsAdmin = (state) => state.user.admin;

export default user;
