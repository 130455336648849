export function formatTimestamp(tsmp) {
  const timestamp = new Date(tsmp);
  const date = timestamp.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return `${date}`;
};
