import React, { useEffect, useMemo, useRef, useState } from "react";
import * as ExcelJS from "exceljs";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { formatTimestamp } from "../../utils/formatTimestamp";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack, styled } from "@mui/system";
import {
  ArrowDownward,
  ArrowForwardIos,
  ArrowUpward,
  Close,
  Download,
  FilterAltOff,
  MoreVert,
  Refresh,
  Search,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import {
  ArrowDropDownIcon,
  ClearIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { useDispatch } from "react-redux";
import { setActiveFeedback } from "../../redux/reducers/feedbackReducer";
import DetailView from "../DetailView";
import { useModal } from "mui-modal-provider";
import { isArray } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { fetchAllFeedbacks } from "../../utils/queries";
import { logoutUser } from "../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

/**
 * Compares 2 dates
 * @param {*} date1
 * @param {*} date2
 * @returns boolean
 */
function compareDates(date1, date2) {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  if (year1 !== year2) {
    return year1 - year2;
  }

  if (month1 !== month2) {
    return month1 - month2;
  }

  return day1 - day2;
}

const dateRangeFilter = (row, columnId, value, addMeta) => {
  let fromRank = true;
  let untilRank = true;

  if (value?.from) {
    const compareFrom = compareDates(
      new Date(row.getValue(columnId)),
      new Date(dayjs(value?.from).format())
    );
    fromRank = compareFrom >= 0 ? true : false;
  }
  if (value?.until) {
    const compareUntil = compareDates(
      new Date(row.getValue(columnId)),
      new Date(dayjs(value?.until).format())
    );
    untilRank = compareUntil <= 0 ? true : false;
  }

  return untilRank && fromRank;
};

const categoryFilter = (row, columnId, value, addMeta) => {
  var mainCategoryRank = null;
  var subCategoryRank = null;

  if (columnId == "mainCategory") {
    mainCategoryRank = rankItem(row.getValue(columnId).id, value.id);

    addMeta(mainCategoryRank);
  }

  if (columnId == "subCategory") {
    subCategoryRank = rankItem(row.getValue(columnId).id, value.id);

    addMeta(subCategoryRank);
  }

  return subCategoryRank ? subCategoryRank.passed : mainCategoryRank.passed;
};

const creatorFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId)?.id, value?.id);

  addMeta(itemRank);

  return itemRank.passed;
};

const recipientFilter = (row, columnId, value, addMeta) => {
  return row.getValue(columnId)?.some((el) => el.id === value?.id);
};

const divisionFilter = (row, columnId, value, addMeta) => {
  return row.getValue(columnId)?.division === value;
};

const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;

  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank,
      rowB.columnFiltersMeta[columnId]?.itemRank
    );
  }

  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

function Filter({ column, table }) {
  const theme = useTheme();
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  const FilterTextField = styled(TextField)({
    color: theme.palette.grayScale.main,
    backgroundColor: theme.palette.primary.contrastText,
    minWidth: "200px",
  });

  dayjs.locale("de");

  if (column.id === "timestamp") {
    return (
      <div>
        <Stack direction={"column"} gap={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              sx={{ width: "160px" }}
              closeOnSelect
              disableFuture
              label="Von"
              slotProps={{
                textField: {
                  variant: "filled",
                },
                openPickerIcon: {
                  sx: { color: theme.palette.primary.main },
                },
              }}
              value={columnFilterValue?.from ?? null}
              onChange={(newValue) =>
                column.setFilterValue({ ...columnFilterValue, from: newValue })
              }
            />
            <DatePicker
              sx={{ width: "160px" }}
              closeOnSelect
              disableFuture
              label="Bis"
              slotProps={{
                textField: {
                  variant: "filled",
                },
                openPickerIcon: {
                  sx: { color: theme.palette.primary.main },
                },
              }}
              value={columnFilterValue?.until ?? null}
              onChange={(newValue) =>
                column.setFilterValue({ ...columnFilterValue, until: newValue })
              }
            />
          </LocalizationProvider>
        </Stack>
        <div className="h-1" />
      </div>
    );
  }

  if (column.id === "mainCategory" || column.id === "subCategory") {
    const subColumn = table?.getColumn("subCategory");

    const uniqueMainCategory = [
      ...new Set(sortedUniqueValues.slice(0, 5000)),
    ].map((main) => ({ id: main.id, label: main.name }));

    const uniqueSubCategory = [
      ...new Set(
        Array.from(subColumn?.getFacetedUniqueValues()?.keys())
          ?.sort()
          ?.slice(0, 5000)
      ),
    ].map((sub) => ({ id: sub.id, label: sub.name }));

    const subFilterValue = subColumn?.getFilterValue();

    return (
      <div>
        <div className="flex space-x-2">
          <Autocomplete
            value={columnFilterValue ?? ""}
            onChange={(e, newValue) =>
              newValue
                ? column.setFilterValue(newValue)
                : column.setFilterValue("")
            }
            placeholder={`Hauptkategorie (${uniqueMainCategory?.length})`}
            options={uniqueMainCategory || []}
            clearIcon={<ClearIcon sx={{ color: theme.palette.primary.main }} />}
            popupIcon={
              <ArrowDropDownIcon sx={{ color: theme.palette.primary.main }} />
            }
            renderInput={(params) => (
              <FilterTextField
                size="small"
                variant="filled"
                {...params}
                label="Hauptkategorie filtern"
              />
            )}
          />

          <Autocomplete
            value={subFilterValue ?? ""}
            disabled={!columnFilterValue}
            onChange={(e, newValue) => {
              newValue
                ? subColumn.setFilterValue(newValue)
                : subColumn.setFilterValue("");
            }}
            placeholder={`Unterkategorie (${uniqueSubCategory?.length})`}
            options={uniqueSubCategory || []}
            clearIcon={<ClearIcon sx={{ color: theme.palette.primary.main }} />}
            popupIcon={
              <ArrowDropDownIcon sx={{ color: theme.palette.primary.main }} />
            }
            renderInput={(params) => (
              <FilterTextField
                size="small"
                variant="filled"
                {...params}
                label="Unterkategorie filtern"
              />
            )}
          />
        </div>
        <div className="h-1" />
      </div>
    );
  }

  if (column.id === "creator") {
    const uniqueCreators = new Map();
    sortedUniqueValues.forEach((obj) => {
      uniqueCreators.set(obj.id, obj);
    });

    return (
      <Autocomplete
        value={columnFilterValue?.label ?? ""}
        onChange={(e, newValue) =>
          newValue ? column.setFilterValue(newValue) : column.setFilterValue("")
        }
        placeholder={`Ersteller (${uniqueCreators?.size})`}
        options={
          [...uniqueCreators.values()]?.map((creator) => {
            return {
              id: creator.id,
              label: creator.firstname + " " + creator.lastname,
            };
          }) || []
        }
        clearIcon={<ClearIcon sx={{ color: theme.palette.primary.main }} />}
        popupIcon={
          <ArrowDropDownIcon sx={{ color: theme.palette.primary.main }} />
        }
        renderInput={(params) => (
          <FilterTextField
            size="small"
            variant="filled"
            {...params}
            label="Ersteller filtern"
          />
        )}
      />
    );
  }

  if (column.id === "recipients") {
    const uniqueRecipients = new Map();
    sortedUniqueValues.flat().forEach((obj) => {
      uniqueRecipients.set(obj.id, obj);
    });
    return (
      <Autocomplete
        value={columnFilterValue?.label ?? ""}
        onChange={(e, newValue) =>
          newValue ? column.setFilterValue(newValue) : column.setFilterValue("")
        }
        placeholder={`Empfänger (${uniqueRecipients?.size})`}
        options={
          [...uniqueRecipients.values()]?.map((creator) => {
            return {
              id: creator.id,
              label: creator.firstname + " " + creator.lastname,
            };
          }) || []
        }
        clearIcon={<ClearIcon sx={{ color: theme.palette.primary.main }} />}
        popupIcon={
          <ArrowDropDownIcon sx={{ color: theme.palette.primary.main }} />
        }
        renderInput={(params) => (
          <FilterTextField
            size="small"
            variant="filled"
            {...params}
            label="Filtern"
          />
        )}
      />
    );
  }

  return <></>;
}

/**
 * @returns
 */
export default function AdminTable({ ...props }) {
  const queryClient = useQueryClient();
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const navigate = useNavigate();
  const { data, error} = useQuery("all_feedbacks", fetchAllFeedbacks);
  const theme = useTheme();
  const refreshData = () => {
    queryClient.invalidateQueries("all_feedbacks");
    toast.info("Aktuelle Daten werden abgerufen.");
  };
  const removeFilters = () => setColumnFilters([]);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const [columnVisibility, setColumnVisibility] = useState({
    actions: true,
    mainCategory: true,
    subCategory: false,
    creator: true,
    message: false,
    recipients: true,
    timestamp: true,
    title: true,
    comment: false,
  });

  useEffect(() => {
    if (error?.response?.status === 403) {
      dispatch(logoutUser());
      navigate("/login");
      toast.error("Fehlende Berechtigungen: bitte loggen Sie sich erneut ein.");
    }
  }, [error]);

  const handleExcelExport = () => {
    const recipientsToString = (recipients) => {
      var recipientString = "";
      if (recipients && Array.isArray(recipients) && recipients?.length > 0) {
        recipients.forEach((recipient, index) => {
          recipientString += `${index > 0 ? ", " : ""}${recipient?.firstname} ${
            recipient?.lastname
          } (${recipient?.email})`;
        });
      }
      return recipientString;
    };

    const copyRecipientsToString = (copyRecipients, ccEmails) => {
      var recipientString = "";
      if (copyRecipients && Array.isArray(copyRecipients) && copyRecipients?.length > 0) {
        copyRecipients.forEach((recipient, index) => {
          recipientString += `${index > 0 ? ", " : ""}${recipient?.firstname} ${
            recipient?.lastname
          } (${recipient?.email})`;
        });
      }
      if(ccEmails){
        if(recipientString == ""){
          recipientString = ccEmails.join(", ");
        }else{
          recipientString = recipientString + ", " + ccEmails.join(", ");
        }
      }

      return recipientString;
    };

    // Create a workbook and worksheet
    var wb = new ExcelJS.Workbook();
    wb.created = new Date();
    var ws = wb.addWorksheet("Feedbacks");

    ws.columns = [
      { header: "Thema", key: "title", width: 40 },
      { header: "Feedback", key: "message", width: 70 },
      { header: "Hauptkategorie", key: "mainCategory", width: 20 },
      { header: "Unterktageorie", key: "subCategory", width: 20 },
      { header: "Empfänger", key: "recipients", width: 40 },
      { header: "In Kopie an", key: "copyRecipients", width: 30 },
      { header: "Ersteller", key: "creator", width: 40 },
      { header: "Datum", key: "timestamp", width: 20 },
      { header: "Persönliche Nachricht", key: "comment", width: 35 },
      { header: "Anhänge", key: "files", width: 15 },
      { header: "Ideenplattform", key: "isBscThread", width: 15 },
    ];
    ws.autoFilter = "A1:K1";

    // Convert JSON data to worksheet
    var jsonData = table
      ?.getSortedRowModel()
      ?.rows?.map((row) => row.original)
      ?.map((el) => {
        return {
          ...el,
          mainCategory: el.mainCategory.name,
          subCategory: el.subCategory.name,
          recipients: el?.recipients ? recipientsToString(el?.recipients?.filter(contact => !!contact?.isMain)) : "",
          copyRecipients: copyRecipientsToString(el?.recipients?.filter(contact => !contact?.isMain), el?.ccEmails),
          creator: el?.creator
            ? `${el?.creator?.firstname} ${el?.creator?.lastname} (${
                el?.creator?.email ?? "keine Email-Adresse verfügbar"
              })`
            : "",
          timestamp: formatTimestamp(el?.timestamp),
          comment: el?.comment ?? "-",
          files: el?.files ? (el?.files?.length > 0 ? "ja" : "nein") : "",
          isBscThread: el?.isBscThread ? "ja" : "nein",
        };
      });

    ws.addRows(jsonData);
    ws.eachRow(function (row, rowNumber) {
      row.alignment = { wrapText: true, vertical: "top"};
    });

    ws.getRow(1).font = { bold: true, color: { argb: "FFFFFF" }};
    ws.getRow(1).alignment = { vertical: "middle"};
    ws.getRow(1).height = 20;
    ws.getRow(1).eachCell({ includeEmpty: false }, function (cell) {
      ws.getCell(cell.address).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002D59" },
        bgColor: { argb: "002D59" },
      };
    });
    
    wb.xlsx
      .writeBuffer()
      .then(function (buffer) {
        // Create a Blob from the buffer
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.download = `Feedbacks.xlsx`;

        // Append the link to the document body and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        URL.revokeObjectURL(url);
      })
      .catch(function (error) {
        console.log("Error writing Excel file:", error);
      });
  };

  /**
   * Dispatches selection of active Feedback to redux store
   * @param {Object} id - feedback id
   * @returns {void}
   */
  const dispatchFeedback = (feedback) => {
    dispatch(setActiveFeedback(feedback, true));
  };

  /**
   * Defines and opens detail view dialog
   * @param {boolean} id - Marks if the selected feedback is open or sent
   * @returns {void}
   */
  const showDetailViewModal = (sent) => {
    const detailView = showModal(DetailView, {
      sent,
      onCancel: () => {
        detailView.destroy();
      },
      onConfirm: () => {
        detailView.destroy();
      },
    });
  };

  /**
   * Handles the Tab change
   * @param {Event} e - The event object representing the click event.
   * @param {String} id - feedback id (uuid)
   * @param {boolean} id - Marks if the selected feedback is open or sent
   * @returns {void}
   */
  const handleDetailView = (e, feedback) => {
    e.preventDefault();
    e.stopPropagation();
    dispatchFeedback(feedback);
    showDetailViewModal(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: () => "Thema",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "message",
      },
      {
        accessorKey: "mainCategory",
        filterFn: "category",
        header: () => "Kategorie",
        cell: (info) =>
          info?.row?.original?.mainCategory?.name +
          " > " +
          info?.row?.original?.subCategory?.name,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "subCategory",
        filterFn: "category",
      },
      {
        accessorKey: "comment",
      },
      {
        accessorKey: "recipients",
        filterFn: "recipient",
        header: () => "Empfänger",
        cell: (info) =>
          info?.row?.original?.recipients?.filter(recipient => recipient.isMain)?.map((recipient, index) => (
            <span key={`${info?.row?.original.id}-recipient${index}`}>
              {recipient?.firstname + " " + recipient?.lastname}
              <br />
            </span>
          )),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "creator",
        filterFn: "creator",
        header: () => "Ersteller",
        cell: (info) =>
          info?.row?.original?.creator?.firstname +
          " " +
          info?.row?.original?.creator?.lastname,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "timestamp",
        filterFn: "dateRange",
        header: () => "Datum",
        cell: (info) => formatTimestamp(info?.row?.original?.timestamp),
        footer: (props) => props.column.id,
      },
      {
        id: "actions",
        cell: (info) => (
          <Tooltip
            title={
              <Typography variant="subtitle1">
                Detailansicht anzeigen
              </Typography>
            }
          >
            <IconButton
              onClick={(e) => handleDetailView(e, info?.row?.original)}
            >
              <ArrowForwardIos sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Tooltip>
        ),
        footer: (props) => props.column.id,
      },
    ],
    []
  );
  const TableHeaderFilterCell = ({ header }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <TableHeaderCell key={header.id} colSpan={header.colSpan}>
        {header.isPlaceholder ? null : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              gap={1}
              sx={{ padding: "10px 0" }}
            >
              <Stack
                sx={{ cursor: "pointer" }}
                direction="row"
                alignItems="center"
                gap="2px"
                onClick={(e) => header.column.toggleSorting()}
              >
                {header.column.getIsSorted() ? (
                  header.column.getIsSorted() === "asc" ? (
                    <ArrowUpward sx={{ fontSize: "1.1rem" }} />
                  ) : (
                    <ArrowDownward sx={{ fontSize: "1.1rem" }} />
                  )
                ) : null}
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </Stack>

              <Tooltip
                title={
                  <Typography variant="subtitle1">Weitere Optionen</Typography>
                }
              >
                <MoreVert sx={{ fontSize: "20px" }} onClick={handleClick} />
              </Tooltip>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{ minWidth: "300px" }}
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems={"center"}
                  padding={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => header.column.toggleSorting(false, false)}
                >
                  <ArrowUpward
                    sx={{
                      color: theme.palette.grayScale.main,
                      fontSize: "1.25rem",
                    }}
                  />
                  <Typography variant="body1">Aufsteigend sortieren</Typography>
                </Stack>
                <Stack
                  direction="row"
                  gap={1}
                  alignItems={"center"}
                  padding={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => header.column.toggleSorting(true, false)}
                >
                  <ArrowDownward
                    sx={{
                      color: theme.palette.grayScale.main,
                      fontSize: "1.25rem",
                    }}
                  />
                  <Typography variant="body1">Absteigend sortieren</Typography>
                </Stack>
                {header.column.getCanFilter() &&
                header.column.id !== "title" ? (
                  <>
                    <Divider />
                    <Box padding={0.5}>
                      <Filter column={header.column} table={table} />
                    </Box>
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems={"center"}
                      padding={1}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        header.column.setFilterValue("");
                      }}
                    >
                      <FilterAltOff
                        sx={{
                          color: theme.palette.grayScale.main,
                          fontSize: "1.25rem",
                        }}
                      />
                      <Typography variant="body1">Filter löschen</Typography>
                    </Stack>
                  </>
                ) : null}
              </Popover>
            </Stack>
          </>
        )}
      </TableHeaderCell>
    );
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      dateRange: dateRangeFilter,
      category: categoryFilter,
      creator: creatorFilter,
      recipient: recipientFilter,
      division: divisionFilter,
    },
    state: {
      columnFilters,
      columnVisibility,
      globalFilter,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    /*if (table.getState().columnFilters[0]?.id === 'fullName') {
      if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }*/
  }, [table.getState().columnFilters[0]?.id]);

  const TableHeaderCell = styled(TableCell)({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    verticalAlign: "top",
    fontSize: "1rem",
  });

  const TableBodyCell = styled(TableCell)({
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.grayScale.main,
    fontSize: "0.9rem",
  });

  const getFilterLabel = (filter) => {
    switch (filter.id) {
      case "mainCategory":
        return filter.value ? `Hauptkategorie: ${filter.value.label}` : "";
      case "subCategory":
        return filter.value ? `Unterkategorie: ${filter.value.label}` : "";
      case "recipients":
        return filter.value ? `Empfänger: ${filter.value.label}` : "";
      case "creator":
        return filter.value ? `Ersteller: ${filter.value.label}` : "";
      case "timestamp":
        return filter.value
          ? filter.value.from && filter.value.until
            ? `Datum: ${formatTimestamp(
                dayjs(filter.value.from)
              )} - ${formatTimestamp(dayjs(filter.value.until))}`
            : filter.value.from && !filter.value.until
            ? `Von: ${formatTimestamp(dayjs(filter.value.from))}`
            : `Bis: ${formatTimestamp(dayjs(filter.value.until))}`
          : "";
      default:
        return "test";
    }
  };

  if (data) {
    return (
      <div className="p-2">
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          sx={{ paddingBottom: "10px" }}
        >
          <Stack direction={"column"}>
            <Tooltip
              placement="top-end"
              title={
                <Typography variant="subtitle1">
                  Textsuche für Thema, Feedback-Text und persönliche Nachricht
                </Typography>
              }
            >
              <TextField
                type="text"
                sx={{ width: "40vw", minWidth: "200px", maxWidth: "800px" }}
                value={globalFilter ?? ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder={"Suchbegriff"}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        sx={{
                          fontSize: 24,
                          color: theme.palette.primary.contrastText,
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment:
                    globalFilter !== "" ? (
                      <InputAdornment position="end">
                        <Close
                          onClick={() => setGlobalFilter("")}
                          sx={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                }}
              />
            </Tooltip>
            {columnFilters &&
              isArray(columnFilters) &&
              columnFilters?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "2px 10px",
                    padding: "5px",
                    maxWidth: "800px",
                  }}
                >
                  {columnFilters.map((filter) => (
                    <Chip
                      key={`filter-${getFilterLabel(filter)}`}
                      variant="outlined"
                      sx={{ width: "fit-content" }}
                      onDelete={() => {
                        table?.getColumn(filter.id)?.setFilterValue("");
                      }}
                      label={getFilterLabel(filter)}
                    />
                  ))}
                </div>
              )}
          </Stack>

          <Stack direction="row" gap={1}>
            <Tooltip
              title={
                <Typography variant="subtitle1">
                  Alle Filter entfernen
                </Typography>
              }
            >
              <IconButton
                sx={{
                  border: "1px solid white",
                  borderRadius: "10%",
                  padding: 0,
                }}
                onClick={removeFilters}
              >
                <FilterAltOff />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={<Typography variant="subtitle1">Excel Export</Typography>}
            >
              <IconButton
                sx={{
                  border: "1px solid white",
                  borderRadius: "10%",
                  padding: 0,
                }}
                onClick={handleExcelExport}
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography variant="subtitle1">Daten aktualisieren</Typography>
              }
            >
              <IconButton
                sx={{
                  border: "1px solid white",
                  borderRadius: "10%",
                  padding: 0,
                }}
                onClick={refreshData}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <div style={{ width: "100%" }}>
          <Table ref={tableRef}>
            <TableHead>
              {table?.getHeaderGroups()?.map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup?.headers?.map((header, index) => {
                    if (header.column.id === "actions") {
                      return (
                        <TableHeaderCell
                          key={header.id + "-" + index}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap="10px"
                                sx={{ padding: "10px" }}
                              >
                                <Stack
                                  sx={{ cursor: "pointer" }}
                                  direction="row"
                                  alignItems="center"
                                  gap="2px"
                                  onClick={(e) => header.column.toggleSorting()}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </Stack>
                              </Stack>
                            </>
                          )}
                        </TableHeaderCell>
                      );
                    }
                    return (
                      <TableHeaderFilterCell
                        key={"filter-" + header.column.id}
                        header={header}
                      />
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table?.getRowModel()?.rows?.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {row?.getVisibleCells()?.map((cell) => {
                      return (
                        <TableBodyCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableBodyCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            sx={{
              backgroundColor: theme.palette.grayScale.contrastText,
              width: "100%",
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={table?.getPrePaginationRowModel()?.rows?.length}
            rowsPerPage={table?.getState().pagination.pageSize}
            page={table?.getState().pagination.pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            onRowsPerPageChange={(e) =>
              table.setPageSize(Number(e.target.value))
            }
            labelRowsPerPage="Einträge pro Seite"
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} von ${
                count !== -1 ? count : `Mehr als ${to}`
              }`;
            }}
          />
        </div>
        <div className="h-2" />
      </div>
    );
  } else {
    return <CircularProgress />;
  }
}
