/**
 * Returns string avatar based on name and theme
 * @param {string} name 
 * @param {string} theme - theme name
 * @returns 
 */
export function stringAvatar(name, theme) {
  return {
    sx: {
      bgcolor: theme.palette.secondary.main,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
