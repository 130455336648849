import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LogoutIcon from "@mui/icons-material/Logout";
import { InfoOutlined, SupervisorAccount } from "@mui/icons-material";
import { logoutUser, userIsAdmin } from "../../redux/reducers/userReducer";
import { useMutation } from "react-query";
import { logout } from "../../utils/queries";
import { Online } from "react-detect-offline";

/**
 * Menu bar fixed at the bottom of the application
 */
export default function BottomBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isAdmin = useSelector(userIsAdmin);

  const [open, setOpen] = React.useState(false);

  /**
   * Toogles drawer for further menu items
   */
  const toggleDrawer = () => {
    setOpen((open) => !open);
  };

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      dispatch(logoutUser());
      navigate("/login");
    },
  });

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: "auto",
          bottom: 0,
          backgroundColor: "#002D59",
        }}
      >
        <Toolbar sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="space-evenly"
            width="100%"
          >
            <Button
              sx={{ minWidth: "40%", color: "white" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Erfassung
            </Button>
            <Button
              sx={{ minWidth: "40%", color: "white" }}
              onClick={() => {
                navigate("/sammlung");
              }}
            >
              Meine Sammlung
            </Button>
            <IconButton size="large" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </Stack>
        </Toolbar>
        <Drawer
          open={open}
          onClose={toggleDrawer}
          hideBackdrop={false}
          anchor="right"
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              padding: "1rem",
              width: isMobile ? "60%" : "20%",
              color: "white",
            },
          }}
        >
          <Stack divider={<Divider />}>
            {isAdmin && (
              <Online>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/admin")}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="left"
                    padding="20px 0"
                  >
                    <SupervisorAccount />
                    <Typography variant="h6">Admin-Bereich</Typography>
                  </Stack>
                </Box>
              </Online>
            )}
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/nutzung")}
            >
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="left"
                padding="20px 0"
              >
                <InfoOutlined />
                <Typography variant="h6">Präambel/Nutzungshinweise</Typography>
              </Stack>
            </Box>
            <Box sx={{ cursor: "pointer" }} onClick={handleLogout}>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="left"
                padding="10px 0"
              >
                <LogoutIcon />
                <Typography variant="h6">Ausloggen</Typography>
              </Stack>
            </Box>
          </Stack>
        </Drawer>
      </AppBar>
    </>
  );
}
