import { createSelector } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { fetchOneFeedback } from "../../utils/queries";

const SET_ACTIVE_FEEDBACK = "app/feedback/SET_ACTIVE_FEEDBACK";
const DELETE_FEEDBACK = "app/feedback/DELETE_FEEDBACK";
const RESET_ACTIVE_FEEDBACK = "app/feedback/RESET_ACTIVE_FEEDBACK";
const ADD_OPEN_FEEDBACK = "app/feedback/ADD_OPEN_FEEDBACK";
const CHANGE_OPEN_FEEDBACK = "app/feedback/CHANGE_OPEN_FEEDBACK";
const ADD_SENT_FEEDBACK = "app/feedback/ADD_SENT_FEEDBACK";
const RESET_OPEN_FEEDBACK = "app/feedback/RESET_OPEN_FEEDBACK";
const RESET_SENT_FEEDBACK = "app/feedback/RESET_SENT_FEEDBACK";
const RESET_FEEDBACK = "app/feedback/RESET_FEEDBACK";

const initialState = {
  active: {
    title: "",
    message: "",
    timestamp: "",
    files: [],
    mainCategory: {id: null, name: null},
    subCategory: {id: null, name: null},
  },
  open: [],
  sent: [],
};

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_FEEDBACK: {
      if (action.isSent) {
        return {
          ...state,
          active: action.feedback || {},
        };
      }
      return {
        ...state,
        active: { ...state.open.filter((i) => i.id == action.feedback?.id)[0] } || {},
      };
    }
    case DELETE_FEEDBACK: {
      if (action.id) {
        return {
          ...state,
          open: state.open.filter((i) => i.id !== action.id) || [],
          active: initialState.active,
        };
      }
      return {...state}
    }
    case RESET_ACTIVE_FEEDBACK: {
      return {
        ...state,
        active: initialState.active,
      };
    }
    case ADD_OPEN_FEEDBACK: {
      const id = uuidv4();
      return {
        ...state,
        open: [...state.open, { id, ...action.feedback }],
        active: { id, ...action.feedback },
      };
    }
    case CHANGE_OPEN_FEEDBACK: {
      const updatedFeedback = action.feedback;
      const updatedOpen = state.open.map((feedback) => {
        return feedback.id == updatedFeedback.id ? updatedFeedback : feedback;
      });

      return {
        ...state,
        active: updatedFeedback,
        open: updatedOpen,
      };
    }
    case ADD_SENT_FEEDBACK: {
      return {
        ...state,
        open: [...state.open.filter((i) => i.id !== action.feedback.id)],
        sent: [...state.sent, action.feedback],
        active: {
          title: "",
          message: "",
          timestamp: "",
          files: [],
        },
      };
    }
    case RESET_OPEN_FEEDBACK: {
      return {
        ...state,
        open: [],
      };
    }
    case RESET_SENT_FEEDBACK: {
      return {
        ...state,
        sent: [],
      };
    }
    case RESET_FEEDBACK: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

//actions
export const setActiveFeedback = (feedback, isSent) => ({
  type: SET_ACTIVE_FEEDBACK,
  feedback,
  isSent,
});
export const resetActiveFeedback = () => ({
  type: RESET_ACTIVE_FEEDBACK,
});
export const addOpenFeedback = (feedback) => ({
  type: ADD_OPEN_FEEDBACK,
  feedback,
});
export const changeOpenFeedback = (feedback) => ({
  type: CHANGE_OPEN_FEEDBACK,
  feedback,
});
export const addSentFeedback = (feedback) => ({
  type: ADD_SENT_FEEDBACK,
  feedback,
});
export const resetFeedback = () => ({
  type: RESET_FEEDBACK,
});
export const deleteFeedback = (id) => ({
  type: DELETE_FEEDBACK,
  id
});

//selector
export const getActiveFeedback = (state) => state.feedback.active;
export const getOpenFeedbacks = createSelector(
  state => state.feedback.open,
  state => state.user.id,
  (openFeedbacks, userKontaktId) => {
    return openFeedbacks.filter(feedback => feedback.creator?.id === userKontaktId);
  }
);
export const getSentFeedbacks = createSelector(
  state => state.feedback.sent,
  state => state.user.id,
  (sentFeedbacks, userKontaktId) => {
    return sentFeedbacks.filter(feedback => feedback.creator?.id === userKontaktId);
  }
);
export const getAllSentFeedbacks = (state) => state.feedback.sent

export default feedback;
