import React from "react";
import { CircularProgress, Dialog, Typography } from "@mui/material";

/**
 * Dialog with loading Spinner
 * @param {boolean} open - represents wether the dialog is open or not
 * @returns
 */
export default function LoadingDialog({ open, title, ...props }) {
  return (
    <Dialog open={open}>
      <Typography variant="h5">{title ?? "Ladevorgang läuft..."}</Typography>
      <CircularProgress style={{ margin: "30px auto" }} />
    </Dialog>
  );
}
