import React, { useEffect } from "react";
import "./App.css";

//REDUX
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store/index";

//ROUTER
import AppRouter from "./routes/AppRouter";

//TOAST
import { ToastContainer, toast } from "react-toastify";
import "./style/toast.css";

//MODAL
import ModalProvider from "mui-modal-provider";

//THEME
import { grey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";

let theme = createTheme({
  typography: {
    fontFamily: "Roboto-Regular",
    fontSize: 10,
    body1: {
      fontSize: 14,
    },
    h3: {
      fontSize: "1.7rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      main: "#002D59",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EE7203",
      contrastText: "#fff",
      light: "#FFBD82",
    },
    grayScale: {
      main: "#3C3C3B",
      contrastText: "#706F6F",
    },
    invertedGrayScale: {
      main: "#706F6F",
      contrastText: grey[100],
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.grayScale.contrastText,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": { fontSize: 25 },
          borderRadius: 0,
          color: grey[100],
          "&.Mui-checked": {
            color: grey[100],
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.grayScale.contrastText,
            color: "#fff",
            padding: "2rem",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontSize: 16,
          textTransform: "initial",
          "&.Mui-disabled": {
            color: "#706F6F",
          },
        },
      },
      variants: [
        {
          props: { variant: "primary" },
          style: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "&.Mui-disabled": {
              backgroundColor: theme.palette.grayScale.contrastText,
              color: theme.palette.grayScale.main,
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            backgroundColor: "none",
            color: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            "&.Mui-disabled": {
              color: theme.palette.grayScale.contrastText,
              border: `1px solid ${theme.palette.grayScale.contrastText}`,
            },
          },
        },
  
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText,
          border: `1px solid ${theme.palette.grayScale.contrastText}`,
          fontSize: 16,
          "&.Mui-disabled": {
            color: "#706F6F",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.contrastText,
          border: `1px solid ${theme.palette.secondary.main}`,
          fontSize: 15,
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            color: theme.palette.grayScale.main,
            backgroundColor: theme.palette.primary.contrastText,
            fontSize: 16,
            "&:hover": {
              backgroundColor: theme.palette.primary.contrastText,
            },
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText,
          fontSize: '0.7rem',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: theme.palette.grayScale.main,
          backgroundColor: theme.palette.primary.contrastText,
          fontSize: 16,
          "&:hover": {
            backgroundColor: theme.palette.primary.contrastText,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          marginTop: "1rem",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#706F6F",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          cursor: "pointer",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          height: "28px",
          marginTop: "5px",
          fontSize: "0.7rem",
          color: theme.palette.primary.contrastText,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: theme.palette.grayScale.contrastText,
          marginBottom: 2,
          "& .MuiListItemSecondaryAction-root": {
            display: "flex",
            alignItems: "center",
            fontSize: 14,
          },
          //"&:hover": {
          //  backgroundColor: theme.palette.grayScale.contrastText,
          //},
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText,
        },
      },
      defaultProps: {
        primaryTypographyProps: {
          variant: "h5",
        },
      },
    },
    MuiTreeView: {
      styleOverrides: {
        root: {
          "& .MuiTreeItem-label": {
            fontSize: 18,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 0,
          },
          "& .MuiTreeItem-content": {
            display: "flex",
            flexDirection: "row-reverse",
            borderBottom: "1px solid #706F6F",
            "&.Mui-selected": {
              backgroundColor: "inherit",
            },
          },
          "&.Mui-selected": {
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiTreeItem-iconContainer": {
            display: "flex",
            alignItems: "center",
            width: "fit-content !important",
            fontSize: 14,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          border: "1px solid #B2B2B2",
          color: "white",
          backgroundColor: "#706F6F",
          minHeight: 30,
          "&.Mui-selected": {
            backgroundColor: "#002D59",
          },
          "&.MuiButtonBase-root": {
            padding: 0,
            fontSize: 16,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grayScale.main,
          color: theme.palette.primary.contrastText,
          fontSize: 12,
          padding: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grayScale.contrastText,
          color: theme.palette.primary.contrastText,
          margin: "0 0 2px 0",
          "&.Mui-expanded": {
            marginBottom: "2px",
            marginTop: "2px",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,
          color: theme.palette.primary.contrastText,
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: "›",
      },
      styleOverrides: {
        root: {
          color: "white",
          "& .MuiTypography": {
            color: "white",
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        describeChild: true,
        placement: "top-start",
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grayScale.main,
          color: theme.palette.grayScale.contrastText,
          fontSize: "100px",
        },
      },
    },
  },
});

function App() {
  useEffect(() => {
    const handleOnline = () => {
      toast.info("Online");
    };

    const handleOffline = () => {
      toast.info("Offline");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const queryClient = new QueryClient();

  return (
    <div id="app">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ModalProvider>
              <AppRouter />
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                theme="dark"
              />
            </ModalProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
