import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import {
  Avatar,
  Button,
  Checkbox,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  CircularProgress
} from "@mui/material";
import Close from "@mui/icons-material/Close";

import { stringAvatar } from "../../utils/stringAvatar";
import { useQuery } from "react-query";
import { fetchCcListContacts } from "../../utils/queries";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

/**
 * Dialog to select MK2 contact data
 * @param {boolean} open - represents wether the dialog is open or not
 * @param {function} onClose - callback Function triggerend on closing event of the dialog
 * @param {function} onConfirm - callback Function triggerend on clicking the confirm button
 * @param {Array} checkedContacts - Array of checked contacts
 * @returns
 */
export default function ContactDialog({
  open,
  onConfirm,
  onCancel,
  checkedContacts,
  ipfChecked,
  optional = false,
  ...props
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(checkedContacts);
  const [sendToIPF, setSendToIPF] = useState(ipfChecked);
  const {
    data: contacts,
    isLoading,
    error,
  } = useQuery(["cc_contacts"], fetchCcListContacts);

  useEffect(() => {
    if (error?.response?.status === 403) {
      dispatch(logoutUser());
      navigate("/login");
      toast.error("Fehlende Berechtigungen: bitte loggen Sie sich erneut ein.");
    }
  }, [error]);

  /**
   * Toggles checked state of the contact
   * @param {Object} contact - object representing contact
   * @returns {void}
   */
  const handleToggle = (contact) => {
    const isChecked = checked.some((el) => el.email === contact.email);
    const newChecked = isChecked
      ? checked.filter((el) => el.email !== contact.email)
      : [...checked, contact];
    setChecked(newChecked);
  };

  /**
   * Toggles if feedback should be send to Ideenplattform
   * @returns {void}
   */
  const toggleIPF = () => {
    setSendToIPF(!sendToIPF);
  };

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      TransitionComponent={Transition}
      anchor="left"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.grayScale.main,
          color: theme.palette.primary.contrastText,
          width: isMobile ? "100%" : "50%",
        },
      }}
    >
      <Stack p={2} gap={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Typography variant="h3" fontWeight="bold">
            MK2/Q: Kontakte
          </Typography>
          <IconButton size="large" onClick={onCancel}>
            <Close size="large" />
          </IconButton>
        </Stack>
        {isLoading ? 
          <CircularProgress sx={{margin: "20px auto",}}/>
        :
        <List
          sx={
            isMobile
              ? { maxHeight: "75dvh", overflowY: "auto" }
              : { maxHeight: "85dvh", overflowY: "auto" }
          }
        >
          {contacts?.map((contact, index) => {
            const labelId = `checkbox-list-secondary-label-${index}`;
            return (
              <ListItem
                key={`contact-${index}`}
                secondaryAction={
                  contact.email ? (
                    <Checkbox
                      edge="end"
                      onChange={() => handleToggle(contact)}
                      checked={
                        !!checked.find((el) => el.email == contact.email)
                      }
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  ) : (
                    <></>
                  )
                }
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      {...stringAvatar(
                        contact.firstname + " " + contact.lastname,
                        theme
                      )}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={contact.firstname + " " + contact.lastname}
                    secondary={
                      <Typography variant="body1">
                        {contact.email ? "(" +contact.email + ")" :
                          "(Keine gültige Email-Adresse hinterlegt)"}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
          <ListItem
            key={`ideenplattform`}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={toggleIPF}
                checked={sendToIPF}
                inputProps={{ "aria-labelledby": "ideenplattform" }}
              />
            }
          >
            <ListItemButton>
              <ListItemText id={0} primary={"Ideenplattform"} />
            </ListItemButton>
          </ListItem>
        </List>
}
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            onConfirm(checked, sendToIPF);
          }}
          disabled={!optional ? checked?.length < 1 && !sendToIPF : false}
        >
          Auswahl übernehmen
        </Button>
      </Stack>
    </Drawer>
  );
}
