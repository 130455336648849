import { combineReducers } from "redux";
import feedback from "../reducers/feedbackReducer"
import user from "./userReducer";

const RESET_APP = "app/rootReducer/RESET_APP";

const reducers = {
  feedback,
  user
};

const appReducer = combineReducers({
  ...reducers,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }

  return appReducer(state, action);
};

export const resetApp = () => {
  return {
    type: RESET_APP,
  };
};

export const resetAppAsync = () => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(resetApp());
    resolve();
  });
};

export default rootReducer;
