import React from "react";
import { Button, Dialog, DialogActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteFeedback } from "../../redux/reducers/feedbackReducer";
import { deleteFile } from "../../utils/indexedDBService";

/**
 * Dialog to delete an open feedback
 * @param {boolean} open - represents wether the dialog is open or not
 * @param {function} onClose - callback Function triggerend on closing event of the dialog
 * @param {Object} feedback- feedback
 * @returns
 */
export default function DeleteDialog({ open, onClose, feedback, ...props }) {
  const dispatch = useDispatch();

  /**
   * Dispatches delete from redux store for feedback with given id
   * @returns {void}
   */
  const handleDelete = () => {

    //delete attachement files
    if (
      feedback?.files &&
      Array.isArray(feedback?.files) &&
      feedback.files?.length > 0
    ) {
      feedback.files.forEach((id) => {
        deleteFile(id);
      });
    }

    dispatch(deleteFeedback(feedback.id));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      Möchten Sie diesen Entwurf wirklich löschen?
      <DialogActions sx={{ marginTop: "20px" }}>
        <Button variant="outlined" onClick={onClose}>
          Abbrechen
        </Button>
        <Button variant="contained" onClick={handleDelete}>
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
