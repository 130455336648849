import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "mui-modal-provider";
import {
  getOpenFeedbacks,
  setActiveFeedback,
} from "../redux/reducers/feedbackReducer";

import Page from "../components/Page";
import DetailView from "../components/DetailView";
import {
  Box,
  Grid,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  CircularProgress
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { DeleteOutlineRounded, EditOutlined } from "@mui/icons-material";
import DeleteDialog from "../components/DeleteDialog";
import { formatTimestamp } from "../utils/formatTimestamp";
import { getUser, logoutUser } from "../redux/reducers/userReducer";
import { useQuery } from "react-query";
import { fetchOwnSendFeedbacks } from "../utils/queries";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Offline, Online } from "react-detect-offline";

const Overview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const user = useSelector((state) => getUser(state));

  const openFeedback = useSelector((state) => getOpenFeedbacks(state));
  const { data: sentFeedback, isLoading, error } = useQuery(['own_feedbacks', user?.id], () => fetchOwnSendFeedbacks(user?.id));

  const [activeTab, setActiveTab] = useState(0);

  useEffect(()=> {
    if(error?.response?.status === 403){
      dispatch(logoutUser());
      navigate("/login");
      toast.error("Fehlende Berechtigungen: bitte loggen Sie sich erneut ein.");
    }
  },[error]);

  /**
   * Handles the Tab change
   * @param {Event} e - The event object representing the tab change.
   * @param {number} index - The index of the newly selected tab.
   * @returns {void}
   */
  const handleChange = (e, index) => {
    setActiveTab(index);
  };

  /**
   * Handles the Tab change
   * @param {Event} e - The event object representing the click event.
   * @param {String} id - feedback id (uuid)
   * @param {boolean} id - Marks if the selected feedback is open or sent
   * @returns {void}
   */
  const handleDetailView = (e, feedback, sent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatchFeedback(feedback, sent);
    showDetailViewModal(sent);
  };

  /**
   * Dispatches selection of active Feedback to redux store
   * @param {String} id - feedback id (uuid)
   * @param {boolean} id - Marks if the selected feedback is open or sent
   * @returns {void}
   */
  const dispatchFeedback = (id, sent) => {
    dispatch(setActiveFeedback(id, sent));
  };

  /**
   * Defines and opens detail view dialog
   * @param {boolean} id - Marks if the selected feedback is open or sent
   * @returns {void}
   */
  const showDetailViewModal = (sent) => {
    const detailView = showModal(DetailView, {
      sent,
      onCancel: () => {
        detailView.destroy();
      },
      onConfirm: () => {
        detailView.destroy();
      },
    });
  };

  /**
   * Defines and opens detail view dialog
   * @param {boolean} id - feedback id
   * @returns {void}
   */
  const handleDelete = (e, feedback) => {
    e.preventDefault();
    e.stopPropagation();

    const deleteDialog = showModal(DeleteDialog, {
      feedback: feedback,
      onClose: () => {
        deleteDialog.destroy();
      },
    });
  };

  /**
   * Filters if feedbacks are older than 2 weeks
   */
  const filterFeedbacks = (criteria, obj) => {
    const timestamp = new Date(obj.timestamp);
    const currentDate = new Date();
  
    const differenceInMs = currentDate - timestamp;
    const twoWeeksInMs = 14 * 24 * 60 * 60 * 1000;
  
    // Check if the difference is greater than two weeks
    const olderThanTwoWeeks = differenceInMs > twoWeeksInMs;
  
    if (criteria == "new") {
      return !olderThanTwoWeeks;
    }
    if (criteria == "old") {
      return olderThanTwoWeeks;
    }
  }
  

  /**
   * Page to provide overview of open and sent feedbacks
   */
  return (
    <Page>
      <Grid>
        <Stack gap={2}>
          <Typography variant={"h3"} fontWeight="bold">
            Sammlung
          </Typography>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="inherit"
            variant="fullWidth"
            indicatorColor="primary"
            TabIndicatorProps={{ hidden: true }}
          >
            <Tab label="Entwürfe" value={0} />
            <Tab label="Versendet" value={1} />
          </Tabs>
        </Stack>
        {activeTab == 0 ? (
          <Stack gap={5}>
            <Box>
              <Stack gap={2}>
                <Typography variant="body1" fontWeight="bold">
                  Diese Woche
                </Typography>

                {openFeedback &&
                  openFeedback
                    .filter((obj) => {
                      return filterFeedbacks("new", obj);
                    })
                    .map((feedback) => {
                      return (
                        <Box
                          sx={{ cursor: "pointer" }}
                          key={`open-new-${feedback.id}`}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ paddingBottom: "10px" }}
                            gap={1}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{ width: "70dvw" }}
                              gap={1}
                              onClick={(e) =>
                                handleDetailView(e, feedback, false)
                              }
                            >
                              <Stack direction="column">
                                <Typography variant="body1" fontWeight="bold">
                                  {feedback.title}
                                </Typography>
                                {(feedback?.mainCategory?.name && feedback?.subCategory?.name) ? (
                                  <Typography
                                    variant="subtitle1"
                                    textAlign="left"
                                  >
                                    {feedback?.mainCategory?.name}
                                    {" > "}
                                    {feedback?.subCategory?.name}
                                  </Typography>
                                ): (
                                  <Typography
                                    variant="subtitle1"
                                    textAlign="left"
                                  >
                                    Keine Kategorie ausgewählt
                                  </Typography>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  textAlign="left"
                                >
                                  {formatTimestamp(feedback.timestamp)}
                                </Typography>
                              </Stack>
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent={"center"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <DeleteOutlineRounded
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => handleDelete(e, feedback)}
                              />
                              <EditOutlined
                                sx={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  handleDetailView(e, feedback, false)
                                }
                              />
                            </Stack>
                          </Stack>
                          <Divider />
                        </Box>
                      );
                    })}
              </Stack>
            </Box>
            <Box>
              <Stack gap={2}>
                <Typography variant="body1" fontWeight="bold" sx={{marginTop: "15px"}}>
                  Älter
                </Typography>
                {openFeedback &&
                  openFeedback
                    .filter((obj) => {
                      return filterFeedbacks("old", obj);
                    })
                    .map((feedback) => {
                      return (
                        <Box
                          sx={{ cursor: "pointer" }}
                          key={`open-old-${feedback.id}`}
                          onClick={(e) =>
                            handleDetailView(e, feedback, false)
                          }
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ paddingBottom: "10px" }}
                            gap={1}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{ width: "70dvw" }}
                              gap={1}
                              onClick={(e) =>
                                handleDetailView(e, feedback, false)
                              }
                            >
                              <Stack direction="column">
                                <Typography variant="body1" fontWeight="bold">
                                  {feedback.title}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  textAlign="left"
                                >
                                  {feedback?.mainCategory?.name}
                                  {" > "}
                                  {feedback?.subCategory?.name}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  textAlign="left"
                                >
                                  {formatTimestamp(feedback.timestamp)}
                                </Typography>
                              </Stack>
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent={"center"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <DeleteOutlineRounded
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => handleDelete(e, feedback)}
                              />
                              <EditOutlined
                                sx={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  handleDetailView(e, feedback, false)
                                }
                              />
                            </Stack>
                          </Stack>
                          <Divider />
                        </Box>
                      );
                    })}
              </Stack>
            </Box>
          </Stack>
        ) : (
          <Stack gap={5}>
            <Online>
            {!isLoading ? 
            <>
            <Box>
              <Stack gap={2}>
                <Typography variant="body1" fontWeight="bold">
                  Diese Woche
                </Typography>

                {sentFeedback &&
                  sentFeedback
                    .filter((obj) => {
                      return filterFeedbacks("new", obj);
                    })
                    .map((feedback) => {
                      return (
                        <Box
                          key={`open-new-${feedback.id}`}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleDetailView(e, feedback, true)
                          }
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ paddingBottom: "10px" }}
                            gap={1}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{ width: "70dvw" }}
                              gap={1}
                            >
                              <Stack direction="column">
                                <Typography variant="body1" fontWeight="bold">
                                  {feedback.title}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  textAlign="left"
                                >
                                  {feedback?.mainCategory?.name}
                                  {" > "}
                                  {feedback?.subCategory?.name}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  textAlign="left"
                                >
                                  {formatTimestamp(feedback.timestamp)}
                                </Typography>
                              </Stack>
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent={"center"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <ChevronRightIcon />
                            </Stack>
                          </Stack>
                          <Divider />
                        </Box>
                      );
                    })}
              </Stack>
            </Box>
            <Box>
              <Stack gap={2}>
                <Typography variant="body1" fontWeight="bold" sx={{marginTop: "15px"}}>
                  Älter
                </Typography>
                {sentFeedback &&
                  sentFeedback
                    .filter((obj) => {
                      return filterFeedbacks("old", obj);
                    })
                    .map((feedback) => {
                      return (
                        <Box
                          key={`open-old-${feedback.id}`}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleDetailView(e, feedback, true)
                          }
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ paddingBottom: "10px" }}
                            gap={1}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{ width: "70dvw" }}
                              gap={1}
                            >
                              <Stack direction="column">
                                <Typography variant="body1" fontWeight="bold">
                                  {feedback.title}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  textAlign="left"
                                >
                                  {feedback?.mainCategory?.name}
                                  {" > "}
                                  {feedback?.subCategory?.name}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  textAlign="left"
                                >
                                  {formatTimestamp(feedback.timestamp)}
                                </Typography>
                              </Stack>
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent={"center"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <ChevronRightIcon />
                            </Stack>
                          </Stack>
                          <Divider />
                        </Box>
                      );
                    })}
              </Stack>
            </Box>
            </>
             :
            <CircularProgress />
}
            </Online>
            <Offline>
              <Box>
                <Typography variant={"h5"}>
                  Bitte stellen Sie eine Internetverbindung her, um gesendete Feedbacks abrufen zu können.
                </Typography>
              </Box>
            </Offline>
          </Stack>
        )}
      </Grid>
    </Page>
  );
};

export default Overview;
