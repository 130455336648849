import React from "react";
import Page from "../components/Page";
import {
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AdminTable from "../components/AdminTable";

const Admin = () => {

  /**
   * Page to provide overview of all sent feedbacks only visible for admins
   */
  return (
    <Page>
      <Grid>
        <Stack gap={2}>
          <Typography variant={"h3"} fontWeight="bold">
            Admin-Bereich
          </Typography>
          <AdminTable/>
        </Stack>
      </Grid>
    </Page>
  );
};

export default Admin;
