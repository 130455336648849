import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "mui-modal-provider";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Online } from "react-detect-offline";

import {
  deleteFeedback,
  getActiveFeedback,
} from "../../../redux/reducers/feedbackReducer";

import Page from "../../../components/Page";
import ContactDialog from "../../../components/ContactDialog";

import {
  Stack,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  Chip,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { getUser } from "../../../redux/reducers/userReducer";
import { Container } from "@mui/system";
import { createFeedback, sendMail } from "../../../utils/queries";
import { useMutation } from "react-query";
import { deleteFilesByIds, getFilesByIds } from "../../../utils/indexedDBService";
import { createFeedbackFormData } from "../../../utils/feedbackFormData";

/**
 * Page for sending/assigning a feedback to an contact person in SBC2
 */
const SBC2 = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const user = useSelector((state) => getUser(state));
  
  const newFeedbackMutation = useMutation({
    mutationFn: createFeedback,
    onSuccess: (data) => {
      if(data?.id){
        sendMailMutation.mutate(data.id)
      }

      if(activeFeedback.files){
        deleteFilesByIds(activeFeedback.files)
      }
      dispatchDeleteFeedback(activeFeedback.id);
      navigate("/sammlung");
      toast.info("Feedback wurde versendet");
    },
  });

  const sendMailMutation = useMutation({
    mutationFn: sendMail,
  });


  const [division, setDevision] = useState("");
  const [comment, setComment] = useState("");
  const [contacts, setContacts] = useState([]);
  const [sendToIPF, setSendToIPF] = useState(false);

  const activeFeedback = useSelector((state) => getActiveFeedback(state));
  const validSteps = ["1", "2"];

  //IndexedDB files
  const [files, setFiles] = useState();

  useEffect(() => {
    fetchFiles();
  },[])

  const fetchFiles = async () => {
    try {
      const result = await getFilesByIds(activeFeedback.files);
      setFiles(result);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Checks if the step defined in the url is valid
   */
  useEffect(() => {
    if (!params.step || !validSteps.includes(params.step)) {
      handleError();
    }
  }, [params.step, validSteps]);

  /**
   * Validates the data of the first step and navigates to the next step
   * @returns {void}
   */
  const handleFirstStep = (e) => {
    if (e.target.value) {
      setDevision(e.target.value);
      navigate("/sbc2/2");
    } else {
      handleError();
    }
  };

  /**
   * Provides error message in toast and navigates to home screen if the
   * step param is not valid
   * @returns {void}
   */
  const handleError = () => {
    toast.error("Entschuldigung! Es ist leider ein Fehler aufgetreten.");
    if (!validSteps.includes(params.step)) {
      navigate("/");
    }
  };

  /**
   * Opens Dialog to select contacts
   * @param {Event} e - The event object representing the click event to select contacts
   * @returns {void}
   */
  const handleSelectContacts = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const contactsDialog = showModal(ContactDialog, {
      checkedContacts: contacts,
      ipfChecked: sendToIPF,
      onCancel: () => {
        contactsDialog.destroy();
      },
      onConfirm: (checked, ipfChecked) => {
        setContacts(checked);
        setSendToIPF(ipfChecked)
        contactsDialog.destroy();
      },
    });
  };

  /**
   * Dispatches to the redux store to delete Feedback from the open list
   * @returns {void}
   */
  const dispatchDeleteFeedback = () => {
    dispatch(deleteFeedback(activeFeedback.id));
  };

  /**
   * Sends Feedback and redirects
   * @param {Event} e - The event object representing the form submit
   * @returns {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const formData = createFeedbackFormData(activeFeedback, division, comment, user.id, sendToIPF, contacts, files)
    if(formData){
      newFeedbackMutation.mutate(formData);
    }
    
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Page>
      <Grid>
        <Stack
          direction="row"
          alignItems="center"
          marginTop={1}
          marginBottom={3}
          onClick={handleBack}
        >
          <ArrowBackIos />
          <Typography variant="subtitle1">Zurück</Typography>
        </Stack>

        {params?.step == 1 && (
          <Stack gap={2}>
            <Typography variant="h4">
              Für welchen Bereich ist es relevant?
            </Typography>
            <Button
              variant="contained"
              color="invertedGrayScale"
              size="large"
              value="SBC2/Q: > STARCLUB"
              fullWidth
              onClick={handleFirstStep}
            >
              STARCLUB
            </Button>
            <Button
              variant="contained"
              color="invertedGrayScale"
              size="large"
              value="SBC2/Q: > Partnerwelt"
              fullWidth
              onClick={handleFirstStep}
            >
              Partnerwelt
            </Button>
          </Stack>
        )}
        {params?.step == 2 && (
          <Stack gap={3}>
            <Typography variant="h4">Weitere Angaben</Typography>
            <Container disableGutters>
              <Typography variant="h5">Zusammenfassung</Typography>
              <Divider sx={{ margin: "5px 0 0 0" }} />
              <Stack
                direction="column"
                sx={{ padding: "5px" }}
                gap={"5px"}
                alignItems="flex-start"
              >
                <Typography fontWeight="bold" variant="body1">
                  {activeFeedback?.title}
                </Typography>
                <Typography variant="body1">
                  {activeFeedback?.message?.slice(0, 90)}
                  {activeFeedback?.message?.length > 90 ? " (...)" : ""}
                </Typography>
                <Typography variant="body1">
                  <Stack direction={"row"} gap={1}>
                    <Chip
                      variant="outlined"
                      label={`${activeFeedback?.mainCategory} > ${activeFeedback?.subCategory}`}
                    />
                    <Chip variant="outlined" label={`${division}`} />
                  </Stack>
                </Typography>
              </Stack>
              <Divider sx={{ margin: "5px 0 0 0" }} />
            </Container>
            <Stack
              onClick={handleSelectContacts}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ cursor: "pointer" }}
            >
              <Typography variant="h5">Feedback senden an</Typography>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="h5">{sendToIPF ? (contacts?.length + 1): contacts?.length}</Typography>
                <ArrowBackIos />
              </Stack>
            </Stack>
            <Stack gap={0}>
              <Typography variant="h5" marginBottom={1} display="flex">
                Persönliche Nachricht
              </Typography>
              <TextField
                hiddenLabel
                fullWidth
                multiline
                placeholder="Ihre persönliche Nachricht (optional)"
                variant="outlined"
                minRows={5}
                maxRows={6}
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </Stack>
            <Online>
              <Button
                variant="secondary"
                size="large"
                fullWidth
                disabled={contacts?.length < 1 && !sendToIPF}
                onClick={handleSubmit}
              >
                Feedback versenden
              </Button>
            </Online>
          </Stack>
        )}
      </Grid>
    </Page>
  );
};

export default SBC2;
